// react core
import { lazy } from "react";

// fonts main
// import { faTowerControl as iconLaunchpadMain } from "@fortawesome/pro-thin-svg-icons";
import { faGrid as iconLaunchpadMain } from "@fortawesome/pro-thin-svg-icons";
import { faCalendars as iconDatesMain } from "@fortawesome/pro-thin-svg-icons";
import { faMapLocation as iconLocationMain } from "@fortawesome/pro-thin-svg-icons";
import { faRocketLaunch as iconEntryMain } from "@fortawesome/pro-thin-svg-icons";
import { faMessage as iconMessagingMain } from "@fortawesome/pro-thin-svg-icons";
import { faShare as iconSharingMain } from "@fortawesome/pro-thin-svg-icons";
import { faCreditCardAlt as iconTransactMain } from "@fortawesome/pro-thin-svg-icons";
import { faUser as iconMembersMain } from "@fortawesome/pro-thin-svg-icons";
// import { faBarcodeRead as iconTicketsMain } from "@fortawesome/pro-thin-svg-icons";
// import { faUserCog as iconManagersMain } from "@fortawesome/pro-thin-svg-icons";
// import { faBadge as iconBadgesMain } from "@fortawesome/pro-thin-svg-icons";

// fonts edit
import { faRocketLaunch as iconLaunchpadEdit } from "@fortawesome/pro-thin-svg-icons";
import { faCalendarPen as iconDatesEdit } from "@fortawesome/pro-thin-svg-icons";
import { faMapLocation as iconLocationEdit } from "@fortawesome/pro-thin-svg-icons";
import { faCommentPen as iconMessagingEdit } from "@fortawesome/pro-thin-svg-icons";
import { faScannerKeyboard as iconEntryEdit } from "@fortawesome/pro-thin-svg-icons";
import { faShare as iconSharingEdit } from "@fortawesome/pro-thin-svg-icons";
import { faCreditCardAlt as iconTransactEdit } from "@fortawesome/pro-thin-svg-icons";
import { faUser as iconMembersEdit } from "@fortawesome/pro-thin-svg-icons";
// import { faNfcPen as iconTicketsEdit } from "@fortawesome/pro-thin-svg-icons";
// import { faUserCog as iconManagersEdit } from "@fortawesome/pro-thin-svg-icons";
// import { faBadge as iconBadgesEdit } from "@fortawesome/pro-thin-svg-icons";

// fonts general
import { faLock as iconDisabled } from "@fortawesome/pro-thin-svg-icons";
import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-solid-svg-icons";
// import { faRocketLaunch as iconTrigger } from "@fortawesome/pro-thin-svg-icons";

// fonts menu
import { faHome as iconHome } from "@fortawesome/pro-solid-svg-icons";
import { faGrid as iconMenu } from "@fortawesome/pro-thin-svg-icons";
import { faAtomSimple as iconEvents } from "@fortawesome/pro-thin-svg-icons";
import { faCogs as iconSettings } from "@fortawesome/pro-thin-svg-icons";
import { faSignIn as iconConnect } from "@fortawesome/pro-thin-svg-icons";
import { faCodePullRequest as iconRequests } from "@fortawesome/pro-thin-svg-icons";
import { faMessage as iconContact } from "@fortawesome/pro-thin-svg-icons";

// import { faUserFriends as iconPeople } from "@fortawesome/pro-thin-svg-icons";
import { faUserCircle as iconPeopleContacts } from "@fortawesome/pro-thin-svg-icons";
// import { faMessagePen as iconPeopleSelfie } from "@fortawesome/pro-thin-svg-icons";
import { faHeadVr as iconProfile } from "@fortawesome/pro-thin-svg-icons";
import { faSignature as iconProfileName } from "@fortawesome/pro-thin-svg-icons";
import { faImageUser as iconProfileAvatar } from "@fortawesome/pro-thin-svg-icons";
import { faCreditCardBlank as iconPayment } from "@fortawesome/pro-thin-svg-icons";
import { faCreditCardAlt as iconPaymentMethods } from "@fortawesome/pro-thin-svg-icons";
import { faReceipt as iconReceipt } from "@fortawesome/pro-thin-svg-icons";
import { faBank as iconIncome } from "@fortawesome/pro-thin-svg-icons";
import { faMoneyBillTransfer as iconIncomeBank } from "@fortawesome/pro-thin-svg-icons";
import { faCoins as iconIncomeBalance } from "@fortawesome/pro-thin-svg-icons";
import { faLock as iconSecurity } from "@fortawesome/pro-thin-svg-icons";
import { faAt as iconSecurityEmail } from "@fortawesome/pro-thin-svg-icons";
import { faUserSecret as iconSecurityPassword } from "@fortawesome/pro-thin-svg-icons";
import { faCheckToSlot as iconSecurityMfa } from "@fortawesome/pro-thin-svg-icons";
import { faPassport as iconAccount } from "@fortawesome/pro-thin-svg-icons";
import { faTrashAlt as iconAccountClose } from "@fortawesome/pro-thin-svg-icons";

// fonts numbers
import { fa1 as icon1 } from "@fortawesome/pro-thin-svg-icons";
import { fa2 as icon2 } from "@fortawesome/pro-thin-svg-icons";
import { fa3 as icon3 } from "@fortawesome/pro-thin-svg-icons";
import { fa4 as icon4 } from "@fortawesome/pro-thin-svg-icons";
import { fa5 as icon5 } from "@fortawesome/pro-thin-svg-icons";
import { fa6 as icon6 } from "@fortawesome/pro-thin-svg-icons";
// import { fa7 as icon7 } from "@fortawesome/pro-thin-svg-icons";
// import { fa8 as icon8 } from "@fortawesome/pro-thin-svg-icons";

// constants
export const APP_MENU_TREE = [
  {
    id: "home",
    name: "Entzy",
    description: "Where to?",
    nav: "/",
    icon: iconHome,
    home: true,
    main: false,
    parent: true,
    bookend: true,
    connected: false,
  },
  // bookend left
  {
    id: "menu",
    name: "Menu",
    nav: "/menu",
    icon: iconMenu,
    main: true,
    parent: true,
    bookend: true,
    connected: false,
  },

  // core functionality menu
  {
    id: "events",
    parentId: "home",
    name: "Eventuators",
    description: "Activity Feed",
    nav: "/",
    icon: iconEvents,
    parent: true,
    main: true,
    connected: true, //todo: set false when ready
  },
  {
    id: "contacts",
    parentId: "home",
    name: "Contacts",
    description: "Your contacts",
    nav: "/contacts",
    icon: iconPeopleContacts,
    parent: true,
    main: true,
    connected: true,
  },
  // {
  //   id: "members",
  //   parentId: "home",
  //   name: "Direct Messages",
  //   description: "Direct Messages",
  //   nav: "/members",
  //   icon: iconPeopleSelfie,
  //   parent: true,
  //   main: true,
  //   connected: true,
  // },

  // settings menu
  {
    id: "settings",
    parentId: "home",
    name: "Settings",
    nav: "/settings",
    description: "Configure your account",
    icon: iconSettings,
    parent: true,
    main: true,
    titleOnly: true,
    connected: true,
  },
  // settings profile menu
  {
    id: "settings-profile",
    parentId: "settings",
    name: "Profile",
    description: "Thinking about a new look?",
    nav: "/settings/profile",
    icon: iconProfile,
    parent: true,
    main: false,
    connected: true,
  },
  {
    id: "settings-profile-name",
    parentId: "settings-profile",
    name: "Name",
    description: "Manage your profile name",
    nav: "/settings/profile/name",
    icon: iconProfileName,
    connected: true,
  },
  {
    id: "settings-profile-avatar",
    parentId: "settings-profile",
    name: "Avatar",
    description: "Manage your profile image",
    nav: "/settings/profile/avatar",
    icon: iconProfileAvatar,
    connected: true,
  },
  // settings payment menu
  {
    id: "settings-payment",
    parentId: "settings",
    name: "Payment",
    description: "Manage your payment settings",
    nav: "/settings/payment",
    icon: iconPayment,
    parent: true,
    main: false,
    connected: true,
  },
  {
    id: "settings-payment-methods",
    parentId: "settings-payment",
    name: "Methods",
    description: "Payment method for rider tickets",
    nav: "/settings/payment/methods",
    icon: iconPaymentMethods,
    connected: true,
  },
  {
    id: "settings-payment-history",
    parentId: "settings-payment",
    name: "Transactions",
    description: "View payment history and receipts",
    nav: "/settings/payment/history",
    icon: iconReceipt,
    connected: true,
  },
  // settings income menu
  {
    id: "settings-income",
    parentId: "settings",
    name: "Income",
    description: "Manage your income settings",
    nav: "/settings/income",
    icon: iconIncome,
    parent: true,
    main: false,
    connected: true,
  },
  {
    id: "settings-income-bank",
    parentId: "settings-income",
    name: "Bank Link",
    description: "Link a bank account for receiving runner income",
    nav: "/settings/income/bank",
    icon: iconIncomeBank,
    connected: true,
  },
  {
    id: "settings-income-balance",
    parentId: "settings-income",
    name: "Transactions",
    description: "View and manage your income account",
    nav: "/settings/income/balance",
    icon: iconIncomeBalance,
    connected: true,
  },
  // settings security menu
  {
    id: "settings-security",
    parentId: "settings",
    name: "Security",
    description: "Manage your security settings",
    nav: "/settings/security",
    icon: iconSecurity,
    parent: true,
    main: false,
    connected: true,
  },
  {
    id: "settings-security-email",
    parentId: "settings-security",
    name: "Email",
    description: "Manage your connected email address",
    nav: "/settings/security/email",
    icon: iconSecurityEmail,
    connected: true,
  },
  {
    id: "settings-security-password",
    parentId: "settings-security",
    name: "Password",
    description: "Manage your connection password",
    nav: "/settings/security/password",
    icon: iconSecurityPassword,
    connected: true,
  },
  {
    id: "settings-security-mfa",
    parentId: "settings-security",
    name: "MFA",
    description: "Setup multi-factor authentication",
    nav: "/settings/security/mfa",
    icon: iconSecurityMfa,
    connected: true,
  },
  // settings account menu
  {
    id: "settings-account",
    parentId: "settings",
    name: "Account",
    description: "Account level settings",
    nav: "/settings/account",
    icon: iconAccount,
    parent: true,
    main: false,
    connected: true,
  },
  {
    id: "settings-account-close",
    parentId: "settings-account",
    name: "Close Account",
    description: "Permanently close your account",
    nav: "/settings/account/close",
    icon: iconAccountClose,
    connected: true,
  },

  // footer main settings
  {
    id: "entzy",
    name: "Entzy",
    main: true,
    titleOnly: true,
    connected: true,
  },
  {
    id: "about",
    name: "About Entzy",
    description: "About Entzy and how it works",
    nav: "/about",
    icon: iconInfo,
    main: true,
    parent: true,
    connected: false,
  },
  {
    id: "features",
    name: "Feature Request",
    description: "Connect with Entzy to improve the platform",
    nav: "/features",
    icon: iconRequests,
    main: true,
    parent: true,
    connected: true,
  },
  {
    id: "support",
    name: "Get in Touch",
    description: "Connect with Entzy for support",
    nav: "/support",
    icon: iconContact,
    main: true,
    parent: true,
    connected: false,
  },
  {
    id: "admin",
    name: "Admin",
    description: "Admin Settings",
    nav: "/admin",
    icon: iconSettings,
    main: true,
    parent: true,
    connected: true,
    admin: true,
  },

  // bookend right
  {
    id: "connect",
    name: "Connect",
    nameAlt: "Disconnect",
    nav: "/connect",
    icon: iconConnect,
    main: true,
    parent: true,
    bookend: true,
    connected: false,
  },

  // {
  //   id: "events-list",
  //   parentId: "events",
  //   name: "Your Events",
  //   description: "Enter existing events",
  //   nav: "/events/list",
  //   icon: iconEventJoin,
  //   connected: false,
  // },
  // {
  //   id: "events-create",
  //   parentId: "events",
  //   name: "Create Event",
  //   description: "Start a new event launcher",
  //   nav: "/events/create",
  //   icon: iconEventCreate,
  //   connected: false,
  // },
  // {
  //   id: "events-entry",
  //   parentId: "events",
  //   name: "Entry",
  //   description: "Ticket scanner and entry",
  //   nav: "/event/entry",
  //   icon: iconEntry,
  //   connected: true,
  // },
  // {
  //   id: "events-runners",
  //   parentId: "events",
  //   name: "My Runners",
  //   nav: "/events/runners",
  //   icon: iconEventsRunners,
  //   connected: true,
  // },
  // {
  //   id: "events-riders",
  //   parentId: "events",
  //   name: "My Riders",
  //   nav: "/events/riders",
  //   icon: iconEventsRiders,
  //   connected: true,
  // },
  // tickets menu
  // {
  //   id: "tickets",
  //   parentId: "home",
  //   name: "Tickets",
  //   description: "Tickets ticking and launched",
  //   nav: "/tickets",
  //   icon: iconTickets,
  //   parent: true,
  //   main: true,
  //   connected: true,
  // },
];

export const EVENT_MODULES = [
  {
    id: "menu",
    name: "Follow the Eventuator Brick Road",
    shortName: "Menu",
    shortSubtext: "Main Menu. Where to?",
    tipText: {
      runners: "Return to eventuator home",
      riders: "Return to eventuator home",
    },
    active: false,
    bookend: true,
    secondaryNav: true,
    visitingRider: true,
    float: "left",
    counterparts: {
      runners: "Menu",
      riders: "Menu",
    },
    description: "Main Menu",
    fallback: "No menu items",
    icon: {
      main: iconMenu,
      edit: iconMenu,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "launchpad",
    name: "Prepare for Launch",
    shortName: "Prepare",
    shortSubtext: "Start",
    tipText: {
      runners: "Start with launch settings",
      riders: "Start with launch details",
    },
    active: true,
    visitingRider: true,
    counterparts: {
      runners: "Criteria",
      riders: "Criteria",
    },
    description: "Launch Criteria",
    fallback: "No launch criteria yet",
    info: "Runners post what's happening and set launch criteria, such as minimum riders and setup notice. No fixed dates yet. Riders review and check out what happens on launch.",
    help: "Check out what's happening and review launch criteria",
    icon: {
      main: iconLaunchpadMain,
      number: icon1,
      edit: iconLaunchpadEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
    noHeaderActions: true,
    noHeaderCounters: true,
    noHeaderDivider: true,
  },
  {
    id: "sharing",
    name: "Share and Promote",
    shortName: "Share",
    shortSubtext: "Promote",
    tipText: {
      runners: "Go to flyer room",
      riders: "Go to flyer room",
    },
    active: true,
    visitingRider: true,
    counterparts: {
      runners: "Flyers",
      riders: "Flyers",
    },
    description: "Promotion Links",
    fallback: "No shares yet",
    info: "Both runners and riders share and promote the eventuator around the web to help discover demand and drive dates towards launch.",
    help: "Promote and share the event link to generate interest",
    icon: {
      main: iconSharingMain,
      number: icon2,
      edit: iconSharingEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "dates",
    name: "Compare Dates Ticking",
    shortName: "Compare",
    shortSubtext: "Dates",
    tipText: {
      runners: "Go to calendar room",
      riders: "Go to calendar room",
    },
    active: true,
    visitingRider: true,
    counterparts: {
      runners: "Offers",
      riders: "Offers",
    },
    description: "Date Offer Exchange",
    fallback: "No offers yet",
    info: "Everyone exchanges dates. Runners offer run dates. Riders offer ticket dates. Dates trigger when demand matches launch criteria. Tickets and payment are exchanged only if dates launch.",
    help: "Everyone exchanges dates. Runners & Riders place offers and dates tick up towards launch...",
    icon: {
      main: iconDatesMain,
      number: icon3,
      edit: iconDatesEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "locations",
    name: "Locate the Launch Site",
    shortName: "Locate",
    shortSubtext: "Site",
    tipText: {
      runners: "Go to map room",
      riders: "Go to map room",
    },
    active: true,
    visitingRider: true,
    counterparts: {
      runners: "Places",
      riders: "Places",
    },
    description: "Where Launches Happen",
    fallback: "No places yet",
    info: "Use maps to exchange ideas on potential launch locations. Riders can make suggestions before runners finalize where it's all happening.",
    help: "Exchange locations and confirm the launch site",
    icon: {
      main: iconLocationMain,
      number: icon4,
      edit: iconLocationEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "messaging",
    name: "Debate Plans and Chat",
    shortName: "Debate",
    shortSubtext: "Chat",
    tipText: {
      runners: "Go to chat rooms",
      riders: "Go to chat rooms",
    },
    active: true,
    counterparts: {
      runners: "Posts",
      riders: "Posts",
    },
    description: "Message Board",
    fallback: "No messages yet",
    info: "Plan, chat and debate with everyone in the event via group messaging or ask AI to join in and help. Runners and riders can communicate before, during and after dates launch.",
    help: "Chat with everyone to validate plans",
    icon: {
      main: iconMessagingMain,
      number: icon5,
      edit: iconMessagingEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "entry",
    name: "Eventuate! Matched Dates",
    shortName: "Eventuate",
    shortSubtext: "Ignition",
    tipText: {
      runners: "Go to ignition room",
      riders: "Go to ignition room",
    },
    active: true,
    counterparts: {
      runners: "Launches",
      riders: "Launches",
    },
    description: "Matched Date Launches",
    fallback: "Entry not available",
    info: "Launch! When dates trigger all matching offers are executed and everyone is notified. Riders are issued tickets. Runners receive their guest lists.",
    help: "Dates matched? Launch! Runners & Riders get together. Rinse and repeat...",
    icon: {
      main: iconEntryMain,
      number: icon6,
      edit: iconEntryEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "members",
    name: "Eventuator Members",
    shortName: "Members",
    shortSubtext: "Members",
    tipText: {
      runners: "Runner Members",
      riders: "Rider Members",
    },
    active: false,
    secondaryNav: true,
    counterparts: {
      runners: "Members",
      riders: "Members",
    },
    description: "Members of this eventuator",
    fallback: "No members yet",
    icon: {
      main: iconMembersMain,
      edit: iconMembersEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "transact",
    name: "In Play Direct Payments",
    shortName: "Transact",
    shortSubtext: "Direct Pay",
    tipText: {
      runners: "Sell products & direct payments",
      riders: "Make direct payments",
    },
    active: false,
    secondaryNav: true,
    float: "left",
    counterparts: {
      runners: "Sales",
      riders: "Purchases",
    },
    description: "Make Direct Payments",
    fallback: "Direct pay unavailable",
    icon: {
      main: iconTransactMain,
      edit: iconTransactEdit,
      close: iconClose,
      disabled: iconDisabled,
    },
  },
  {
    id: "exit",
    name: "Exit Event",
    shortName: "Exit",
    shortSubtext: "Head Out",
    tipText: {
      runners: "Exit the eventuator",
      riders: "Exit the eventuator",
    },
    active: true,
    bookend: true,
    secondaryNav: true,
    visitingRider: true,
    float: "right",
    actionExit: true,
    counterparts: {
      runners: "Exit",
      riders: "Exit",
    },
    description: "Exit and return to events",
    fallback: "Exit unavailable",
    icon: {
      main: iconClose,
      edit: iconClose,
      close: iconClose,
      disabled: iconDisabled,
    },
  },

  // {
  //   id: "managers",
  //   name: "Event Managers",
  //   shortName: "Managers",
  //   active: false,
  //   counterparts: {
  //     runners: "Members",
  //     riders: "Members",
  //   },
  //   description: "The team behind this event",
  //   fallback: "Owner run event",
  //   icon: {
  //     main: iconManagersMain,
  //     edit: iconManagersEdit,
  //     close: iconClose,
  //     disabled: iconDisabled,
  //   },
  // },
  // {
  //   id: "members",
  //   name: "Event Members",
  //   shortName: "Members",
  //   active: false,
  //   counterparts: {
  //     runners: "Members",
  //     riders: "Members",
  //   },
  //   description: "The crowd watching this event",
  //   fallback: "No followers yet",
  //   icon: {
  //     main: iconMembersMain,
  //     edit: iconMembersEdit,
  //     close: iconClose,
  //     disabled: iconDisabled,
  //   },
  // },
  // {
  //   id: "badges",
  //   name: "Member Badges",
  //   shortName: "Badges",
  //   active: false,
  //   counterparts: {
  //     runners: "Badges",
  //     riders: "Requests",
  //   },
  //   description: "Loyalty schemes for members",
  //   fallback: "No badges available",
  //   icon: {
  //     main: iconBadgesMain,
  //     edit: iconBadgesEdit,
  //     close: iconClose,
  //     disabled: iconDisabled,
  //   },
  // },
  // {
  //   id: "tickets",
  //   name: "Ticket Types",
  //   shortName: "Tickets",
  //   active: false,
  //   counterparts: {
  //     runners: "Tickets",
  //     riders: "Offers",
  //   },
  //   description: "View ticket categories",
  //   fallback: "No tickets available",
  //   icon: {
  //     main: iconTicketsMain,
  //     edit: iconTicketsEdit,
  //     close: iconClose,
  //     disabled: iconDisabled,
  //   },
  // },
  // {
  //   id: "details",
  //   name: "Launch Activity",
  //   shortName: "What",
  //   active: true,
  //   counterparts: {
  //     runners: "Posts",
  //     riders: "Ideas",
  //   },
  //   description: "What happens on launch",
  //   fallback: "No launch details yet",
  //   icon: {
  //     main: iconDetailsMain,
  //     edit: iconDetailsEdit,
  //     close: iconClose,
  //     disabled: iconDisabled,
  //   },
  // },
];

// entzy lazy single level components
const EventMenu = lazy(() => import("components/menu/MenuEvent"));

// entzy lazy widget components
const EventWidgetHeader = lazy(() =>
  import("components/event/widgets/WidgetHeader")
);
const EventWidgetDates = lazy(() =>
  import("components/event/widgets/WidgetDates")
);
const EventWidgetMessaging = lazy(() =>
  import("components/event/widgets/WidgetMessaging")
);
const EventWidgetLocations = lazy(() =>
  import("components/event/widgets/WidgetLocations")
);

// entzy lazy room components
const EventRoomLaunchpad = lazy(() =>
  import("components/event/rooms/RoomLaunchpad")
);
const EventRoomSharing = lazy(() =>
  import("components/event/rooms/RoomSharing")
);
const EventRoomDates = lazy(() => import("components/event/rooms/RoomDates"));
const EventRoomMessaging = lazy(() =>
  import("components/event/rooms/RoomMessaging")
);
const EventRoomLocations = lazy(() =>
  import("components/event/rooms/RoomLocations")
);
// const EventRoomDetails = lazy(() =>
//   import("components/event/rooms/RoomDetails")
// );
const EventRoomEntry = lazy(() => import("components/event/rooms/RoomEntry"));
const EventRoomMembers = lazy(() =>
  import("components/event/rooms/RoomMembers")
);

// entzy dynamic widgets
export const renderEventWidget = function (props) {
  const module = EVENT_MODULES.filter((obj) => obj.id === props.id)[0];
  switch (props.id) {
    case "menu":
      return <EventMenu {...props} module={module} />;
    case "header":
      return <EventWidgetHeader {...props} module={module} />;
    case "dates":
      return <EventWidgetDates {...props} module={module} />;
    case "messaging":
      return <EventWidgetMessaging {...props} module={module} />;
    case "locations":
      return <EventWidgetLocations {...props} module={module} />;
    default:
      return <></>;
  }
};

// entzy dynamic rooms
export const renderEventRoom = function (props) {
  const module = EVENT_MODULES.filter((obj) => obj.id === props.id)[0];
  switch (props.id) {
    case "menu":
      // return <EventMenu {...props} module={module} />;
      return <></>;
    case "launchpad":
      return <EventRoomLaunchpad {...props} module={module} />;
    case "sharing":
      return <EventRoomSharing {...props} module={module} />;
    case "dates":
      return <EventRoomDates {...props} module={module} />;
    case "messaging":
      return <EventRoomMessaging {...props} module={module} />;
    case "locations":
      return <EventRoomLocations {...props} module={module} />;
    case "entry":
      return <EventRoomEntry {...props} module={module} />;
    case "members":
      return <EventRoomMembers {...props} module={module} />;
    default:
      return <></>;
  }
};
