// import configEntzy from "components/config/ConfigEntzy";
import { EVENT_MODULES } from "models/Structure";
import { isInteger, getDefaultLargeInt } from "models/Tools";
import { grey, blueGrey, orange, green, red } from "@mui/material/colors";

// fonts and icons
import {
  faFacebook as iconFacebook,
  faInstagram as iconInstagram,
  faLinkedin as iconLinkedin,
  faXTwitter as iconX,
  faYoutube as iconYoutube,
} from "@fortawesome/free-brands-svg-icons";
// tempoary faux guids for use client side
// when waiting for actual guid from server
const getTempoaryGuid = function () {
  let timestamp, random, tmpGuid;
  timestamp = "tmp" + Date.now();
  random = Math.random().toString(36);
  tmpGuid = "";
  tmpGuid += timestamp.substring(0, 3);
  tmpGuid += timestamp.substring(9, 12);
  tmpGuid += random.substring(10, 12) + "-";
  tmpGuid += timestamp.substring(12, 16) + "-";
  tmpGuid += random.substring(2, 6) + "-";
  tmpGuid += random.substring(6, 10);
  return tmpGuid;
};

const EventModules = function () {
  const moduleStates = {};
  // const ACTIVE_MODULES = EVENT_MODULES.filter((obj) => obj.active);
  for (let module of EVENT_MODULES) {
    moduleStates[module.id] = {
      active: module.active,
      open: false,
      text: {
        runner: {
          title: module.id,
          description: "Runner " + module.id,
          action: "Runner Action",
        },
        rider: {
          title: module.id,
          description: "Rider " + module.id,
          action: "Rider Action",
        },
      },
      counters: {
        runner: {
          header: 0,
          viewer: {
            primary: 0,
            secondary: 0,
          },
        },
        rider: {
          header: 0,
          viewer: {
            primary: 0,
            secondary: 0,
          },
        },
      },
    };
  }
  return moduleStates;
};

const EventDateSetting = function (type) {
  return {
    id: getTempoaryGuid(),
    active: true,
    add: [],
    order: 1,
    tag: "Date",
    subtag: type,
  };
};

const EventTicketSetting = function (type, max, name, price) {
  return {
    id: getTempoaryGuid(),
    active: true,
    min: 10,
    max: max ? max : "Standard",
    order: 1,
    name: name ? name : "Standard",
    price: price ? price : 0,
    tag: "TicketType",
    subtag: type,
  };
};

const EventLimitSetting = function (type, value, active) {
  return {
    id: getTempoaryGuid(),
    active: active ? true : false,
    add: [],
    order: 1,
    tag: "Limit",
    subtag: type,
    max: isInteger(value) ? value : getDefaultLargeInt(),
    unit: type === "notice" ? "days" : "quantity",
  };
};
const EventTriggerSetting = function (type, value, active) {
  return {
    id: getTempoaryGuid(),
    active: active ? true : false,
    add: [],
    order: 1,
    tag: "Trigger",
    subtag: type,
    min: isInteger(value) ? value : getDefaultLargeInt(),
    unit: type === "financial" ? "amount" : "quantity",
  };
};

const EventMain = function () {
  return {
    data: {
      EventId: null,
      UserId: null,
      Url: null,
      Country: null,
      Currency: null,
      Active: true,
      Access: "public",
      Privacy: null,
      Tagline: null,
      Details: null,
      WelcomeDetails: null,
      LaunchPerTicket: false,
      Logo: null,
      TicketTypes: [EventTicketSetting("default", 100)],
      Triggers: [
        EventTriggerSetting("default", 50, true),
        EventTriggerSetting("financial"),
      ],
      Limits: [
        EventLimitSetting("notice", 7, true),
        EventLimitSetting("capacity", 100, true),
      ],
      Ticking: [],
      Dates: [],
      Badges: [],
      Socials: [],
      ImageFrames: EventImageFrames(),
    },
    hydrated: false,
    exploring: false,
    owner: false,
    manager: false,
    pinned: null,
    accessQueued: false,
    accessGranted: false,
    modules: EventModules(),
    moduleSelected: "launchpad",
    moduleHasSelected: {},
    lastDataUpdate: {
      key: null,
      time: null,
    },
    generators: {
      settings: {
        date: EventDateSetting,
        ticket: EventTicketSetting,
        limit: EventLimitSetting,
        trigger: EventTriggerSetting,
      },
    },
  };
};

export const EVENT_CONSTANTS = {
  events: {
    maxNameLength: 30,
    maxTaglineLength: 50,
    maxDetailsLength: 20000,
    minLaunchNotice: 0,
    maxLaunchNotice: 180,
    minLaunchGuests: 1,
    maxLaunchGuests: 10000,
    imageFrameCount: 10,
    imageFrameBase: {
      id: "frame0",
      order: 0,
      active: false,
      tag: "frame",
      path: "none",
    },
    cacheExpiry: 2,
    states: [
      { value: "owner", display: "owner", runner: true },
      { value: "manager", display: "manager", runner: true },
      { value: "ticked", display: "ticked", runner: false },
      { value: "pinned", display: "pinned", runner: false },
      { value: "owner-restored", display: "owner", runner: true },
      { value: "manager-restored", display: "manager", runner: true },
      { value: "restored", display: "restored", runner: false },
    ],
  },
  dates: {
    base: "2022-01-01",
    maxDays: 365,
    maxOffers: 20,
    states: [
      { value: "none", display: "ticking", offer: false, launch: false },
      { value: "initiated", display: "ticking", offer: true, launch: false },
      { value: "ticking", display: "ticking", offer: true, launch: false },
      {
        value: "launchable",
        display: "launchable",
        offer: true,
        launch: false,
      },
      { value: "triggered", display: "launched", offer: false, launch: true },
      { value: "cancelled", display: "cancelled", offer: false, launch: true },
      { value: "expired", display: "expired", offer: false, launch: true },
      { value: "updating", display: "updating", offer: false, launch: false },
    ],
  },
  tickets: {
    maxOffers: 10,
    minGroupSize: 1,
    maxGroupSize: 20,
    activeStates: [
      {
        value: "active",
        display: "active",
        color: green[200],
        active: true,
      },
      {
        value: "inactive",
        display: "inactive",
        color: grey[600],
        active: false,
      },
    ],
    states: [
      {
        value: "initiated",
        display: "ticking",
        color: blueGrey[200],
        offer: true,
        launch: false,
      },
      {
        value: "ticking",
        display: "ticking",
        color: orange[200],
        offer: true,
        launch: false,
      },
      {
        value: "triggered",
        display: "launched",
        color: orange[500],
        offer: false,
        launch: true,
        entry: true,
        ticketReady: true,
      },
      {
        value: "checked",
        display: "entered",
        color: green[500],
        offer: false,
        launch: true,
        entry: true,
        entered: true,
      },
      {
        value: "checked:part",
        display: "part entered",
        color: green[500],
        offer: false,
        launch: true,
        entry: true,
        entered: true,
      },
      {
        value: "incomplete",
        display: "payment issue",
        color: red[200],
        offer: false,
        launch: true,
        entry: false,
        paymentRetry: true,
      },
      {
        value: "refunded",
        display: "refunded",
        color: blueGrey[800],
        offer: false,
        launch: true,
        entry: false,
        ticketRefunded: true,
      },
      {
        value: "refunded:part",
        display: "refunded",
        color: blueGrey[600],
        offer: false,
        launch: true,
        entry: false,
      },
      {
        value: "cancelled",
        display: "cancelled",
        color: grey[600],
        offer: false,
        launch: true,
        entry: false,
      },
      {
        value: "withdrawn",
        display: "withdrawn",
        color: grey[600],
        offer: false,
        launch: false,
        entry: false,
      },
      {
        value: "expired",
        display: "expired",
        color: grey[600],
        offer: false,
        launch: false,
        entry: false,
      },
      {
        value: "updating",
        display: "updating",
        color: grey[0],
        offer: false,
        launch: false,
        entry: false,
      },
    ],
  },
  messaging: {
    rooms: [
      {
        name: "main",
        title: "Main Room",
        description: "Everyone",
      },
      {
        name: "runner",
        title: "Runners Room",
        description: "Only Runners",
      },
      {
        name: "rider",
        title: "Riders Room",
        description: "Only Riders",
      },
      {
        name: "aimain",
        title: "AI Main Room",
        description: "Everyone with AI",
      },
      {
        name: "airunner",
        title: "AI Runners Room",
        description: "Runners with AI",
      },
      {
        name: "airider",
        title: "AI Riders Room",
        description: "Riders with AI",
      },
      {
        name: "desk",
        title: "Front Desk",
        description: "Ask Runners",
      },
    ],
  },
  pointers: {
    main: "/e/",
    bot: {
      basic: "/=/",
      styled: "/" + String.fromCharCode(9869) + "/",
    },
  },
};

export const EventObservables = function (props) {
  return {
    list: ["event", "sharing", "locations", "messaging", "entry"], // must match list of objects that follow next
    event: {
      active: false,
      field: "onDeltaEvent",
      subscription: null,
      modules: [
        "launchpad",
        "sharing",
        "dates",
        "locations",
        "messaging",
        "entry",
      ],
      action: {
        type: props.eventActions.RELOAD_EVENT,
        property: "data",
        recalculate: true,
        recalendar: true,
        reticket: true,
      },
    },
    sharing: {
      active: false,
      field: "onDeltaSharing",
      subscription: null,
      modules: ["sharing"],
      action: {
        type: props.eventActions.PULL_LINKS,
        property: "content",
        recalculate: true,
      },
    },
    locations: {
      active: false,
      field: "onDeltaLocations",
      subscription: null,
      modules: ["locations"],
      action: {
        type: props.eventActions.PULL_PLACES,
        property: "content",
        recalculate: true,
      },
    },
    messaging: {
      active: false,
      field: "onPostChatEventContent",
      subscription: null,
      modules: [
        "launchpad",
        "sharing",
        "dates",
        "locations",
        "messaging",
        "entry",
      ],
      params: { RoomName: "main" },
      action: {
        type: props.eventActions.POST_MESSAGE,
        property: "message",
        recalculate: true,
      },
    },
    entry: {
      active: false,
      field: "onEntryHandshake",
      subscription: null,
      modules: ["entry"],
      action: {
        type: props.eventActions.ENTRY_HANDSHAKE,
        property: "handshake",
      },
    },
  };
};
export const EventPinnedRunnerStates = function () {
  const offerStates = [];
  for (let state of EVENT_CONSTANTS.events.states) {
    if (state.runner) {
      offerStates.push(state.value);
    }
  }
  return offerStates;
};
export const EventPinnedRiderStates = function () {
  const offerStates = [];
  for (let state of EVENT_CONSTANTS.events.states) {
    if (!state.runner) {
      offerStates.push(state.value);
    }
  }
  return offerStates;
};

export const EventTicketOfferStates = function () {
  const offerStates = [];
  for (let state of EVENT_CONSTANTS.tickets.states) {
    if (state.offer) {
      offerStates.push(state.value);
    }
  }
  return offerStates;
};
export const EventTicketLaunchStates = function () {
  const offerStates = [];
  for (let state of EVENT_CONSTANTS.tickets.states) {
    if (state.launch) {
      offerStates.push(state.value);
    }
  }
  return offerStates;
};

export const EventImageFrames = function () {
  const imageFrames = [];
  for (let i = 1; i <= EVENT_CONSTANTS.events.imageFrameCount; i++) {
    let imageFrameBase = JSON.parse(
      JSON.stringify(EVENT_CONSTANTS.events.imageFrameBase)
    );
    imageFrameBase.id = "frame" + i;
    imageFrameBase.order = i;
    imageFrames.push(imageFrameBase);
  }
  return imageFrames;
};

export const EventSocials = function () {
  return [
    {
      id: "facebook",
      name: "Facebook",
      icon: iconFacebook,
      active: true,
      campaign: null,
    },
    {
      id: "instagram",
      name: "Instagram",
      icon: iconInstagram,
      active: true,
      campaign: null,
    },
    {
      id: "linkedin",
      name: "LinkedIn",
      icon: iconLinkedin,
      active: false,
      campaign: null,
    },
    {
      id: "x",
      name: "X",
      icon: iconX,
      active: true,
      campaign: null,
    },
    {
      id: "youtube",
      name: "YouTube",
      icon: iconYoutube,
      active: false,
      campaign: null,
    },
  ];
};

export default EventMain;

// const holderPositions = {
//   all: ['owner', 'initiated', 'accepted', 'rejected', 'triggered', 'incomplete', 'complete', 'cancelled', 'updating'],
//   unlocked: ['initiated', 'accepted'],
//   locked: ['owner', 'rejected', 'triggered', 'incomplete', 'complete', 'cancelled'],
//   inactive: ['cancelled', 'rejected'],
//   confirmed: ['owner', 'accepted'],
//   billable: ['owner', 'initiated', 'accepted']
// };

// export const RenderTicketState = (props) => {
//   const state = props.state.replace(":", "");
//   const stacked = props.stacked ? true : false;
//   const display = TICKET_STATES.styles[state]
//     ? TICKET_STATES.styles[state].display
//     : "UNAVAILABLE";
//   const icon = TICKET_STATES.styles[state]
//     ? TICKET_STATES.styles[state].icon
//     : "exclamation-square";
//   const spin = TICKET_STATES.styles[state]
//     ? TICKET_STATES.styles[state].spin
//       ? true
//       : false
//     : false;
//   const classtext = TICKET_STATES.styles[state]
//     ? TICKET_STATES.styles[state].classtext
//     : "EntzyLight";
//   const classicon = TICKET_STATES.styles[state]
//     ? TICKET_STATES.styles[state].classicon
//     : "EntzyLight";
//   return (
//     <span className={classtext}>
//       {
//         <FontAwesomeIcon
//           icon={["fas", icon]}
//           spin={spin}
//           className={classicon}
//           transform={stacked ? "grow-10 up-5" : "shrink-2"}
//         />
//       }
//       {stacked ? <br /> : <span>&nbsp;&nbsp;</span>}
//       {display}
//     </span>
//   );
// };
