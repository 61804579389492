import configAuto from "./ConfigAuto";

const APP_LOCAL_URL = "http://localhost:3000";
const APP_LOCAL_CLOUD_URL = "https://develop.entzy.com";

const APP_URL =
  configAuto.entzy.ENV === "production"
    ? "https://entzy.com"
    : configAuto.entzy.ENV === "local"
    ? APP_LOCAL_URL
    : "https://" + configAuto.entzy.ENV + ".entzy.com";

const APP_LONG_URL =
  configAuto.entzy.ENV === "local"
    ? APP_LOCAL_URL
    : "https://" + configAuto.entzy.ENV + ".entzy.com";
const APP_DOMAIN = APP_URL.split("://")[1];
const APP_LONG_DOMAIN = APP_LONG_URL.split("://")[1];

const APP_COOKIE_DOMAIN =
  configAuto.entzy.ENV === "local" ? "localhost" : APP_DOMAIN;
const APP_COOKIE_SECURE = configAuto.entzy.ENV === "local" ? false : true;

const APP_AUTH_DOMAIN =
  configAuto.entzy.ENV === "production"
    ? "auth.entzy.com"
    : configAuto.entzy.ENV === "local"
    ? "auth.develop.entzy.com"
    : "auth." + configAuto.entzy.ENV + ".entzy.com";

const APP_AUTH_URL =
  "https://" +
  APP_AUTH_DOMAIN +
  "/login?response_type=code&client_id=" +
  configAuto.cognito.APP_CLIENT_ID +
  "&redirect_uri=" +
  APP_URL;

const APP_AUTH_UI_URL =
  "https://" +
  APP_AUTH_DOMAIN +
  "/login?response_type=code&client_id=" +
  configAuto.cognito.APP_CLIENT_ID +
  "&redirect_uri=" +
  APP_URL;

const APP_MAIL_EXCHANGE =
  configAuto.entzy.ENV === "production"
    ? "@entzy.com"
    : configAuto.entzy.ENV === "local"
    ? "@develop.entzy.io"
    : "@" + configAuto.entzy.ENV + ".entzy.com";

const APP_CONTINENT = configAuto.appsync.REGION.startsWith("eu-")
  ? "Europe"
  : configAuto.appsync.REGION.startsWith("us-")
  ? "Americas"
  : "Global";

const POINTER_BOT = String.fromCharCode(9869);
const POINTER_MAIN = "/e/";
const POINTER_BOT_BASIC = "/=/";
const POINTER_BOT_STYLED = "/" + String.fromCharCode(9869) + "/";
const POINTER_BOT_STYLED_HELPER = String.fromCharCode(9869) + "/";
const POINTER_BOT_STYLED_LAUNCHED = "\\" + String.fromCharCode(9869) + "/";

const BASE_SETTINGS = {
  SPACING_XS: { xs: 1, sm: 1, md: 1, lg: 1 },
  SPACING_SM: { xs: 0.2, sm: 0.4, md: 0.6, lg: 0.6 },
  SPACING_MD: { xs: 0.8, sm: 1, md: 1.2, lg: 1.2 },
  SPACING_LG: { xs: 2.4, sm: 2.8, md: 3.2, lg: 3.2 },
  SPACING_XL: { xs: 3.6, sm: 4.4, md: 5.2, lg: 5.2 },
  SPACING_HL: { xs: 6.0, sm: 7.2, md: 8.4, lg: 8.4 },
  SPACING_M2L: { xs: 0.8, sm: 1.4, md: 2.8, lg: 3.2 },
};

const CONFIG_ENTZY = {
  // host settings
  APP_ENV: configAuto.entzy.ENV,
  APP_REGION: configAuto.appsync.REGION,
  APP_VERSION: configAuto.entzy.VERSION,
  APP_TIMESTAMP: configAuto.entzy.TIMESTAMP,
  APP_URL: APP_URL,
  APP_LOCAL_URL: APP_LOCAL_URL,
  APP_LOCAL_CLOUD_URL: APP_LOCAL_CLOUD_URL,
  APP_DOMAIN: APP_DOMAIN,
  APP_LONG_URL: APP_LONG_URL,
  APP_LONG_DOMAIN: APP_LONG_DOMAIN,
  APP_COOKIE_DOMAIN: APP_COOKIE_DOMAIN,
  APP_COOKIE_SECURE: APP_COOKIE_SECURE,
  APP_COOKIE_PATH: "/",
  APP_COOKIE_SAMESITE: "strict",
  APP_COOKIE_EXPIRES_XS: 1 / 24, // 1 hour
  APP_COOKIE_EXPIRES_SM: 1, // 1 day
  APP_COOKIE_EXPIRES_MD: 7, // 1 week
  APP_COOKIE_EXPIRES_LG: 28, // 4 weeks
  APP_COOKIE_EXPIRES_XL: 365, // 1 year
  APP_AUTH_DOMAIN: APP_AUTH_DOMAIN,
  APP_AUTH_URL: APP_AUTH_URL,
  APP_AUTH_UI_URL: APP_AUTH_UI_URL,
  APP_MAIL_EXCHANGE: APP_MAIL_EXCHANGE,
  APP_CONTINENT: APP_CONTINENT,
  APP_GOOGLE_CLIENT_ID: configAuto.google.CLIENT_ID,
  APP_GOOGLE_API_KEY: configAuto.google.API_KEY,
  APP_MAPS_PUBLIC_KEY: configAuto.google.API_KEY,
  APP_PAYMENTS_PUBLIC_KEY: configAuto.stripe.PUBLIC_KEY,
  APP_IMAGE_NAMES: {
    PROFILE_MAIN: "profile-avatar",
    EVENT_MAIN: "event-banner",
  },
  APP_IMG_NAME_EVENT_LOGO: "event-logo",

  // system users
  AI_USER_ID: "ai-user",
  AI_USER_NAME: "entzy",
  RESERVED_NAMES: [
    "entzy",
    "ai",
    "ai-user",
    "admin",
    "root",
    "super",
    "system",
    "support",
  ],

  // fee settings
  FEE_VARIABLE: 0.05,
  FEE_FIXED_USD: 0.4,

  // pages sizes
  PAGE_SIZE_CALENDAR: 500,
  PAGE_SIZE_EVENTS: 50,
  PAGE_SIZE_SHARING: 50,
  PAGE_SIZE_DATES: 50,
  PAGE_SIZE_TICKS: 50,
  PAGE_SIZE_TICKETS: 50,
  PAGE_SIZE_MESSAGING: 50,
  PAGE_SIZE_LOCATIONS: 50,
  PAGE_SIZE_TRANSACTIONS: 50,
  PAGE_SIZE_CONTACTS: 500,
  PAGE_SIZE_ENTRY: 1000,
  PAGE_SIZE_USERS: 50,
  PAGE_SIZE_NOTIFICATIONS: 50,
  PAGE_SIZE_PRODUCTS: 50,

  // timeouts
  IDLE_TIMEOUT_MINS: 10,
  ALERT_TIMEOUT_SECS: 60,

  // delimiters
  DELIMITER_DEFAULT: ":",
  DELIMITER_USERS: "|",

  // min and max values
  MIN_MAX_PROFILE_NAME: [5, 20],
  MIN_MAX_EVENT_NAME: [5, 30],
  MAX_EVENT_DETAILS: 20000,
  CACHE_EXPIRY_MINUTES: 60,
  BACKDROP_COUNT: 12,

  // animations
  TRANSITIONS: { fast: 250, default: 500, slow: 1000 },

  // payments
  STRIPE_CONNECT_ID: configAuto.stripe.CONNECT_ID,
  STRIPE_CONNECT_URL: "https://connect.stripe.com/express/oauth/authorize",
  STRIPE_CONNECT_REDIRECT_URL: APP_URL + "/settings/income/bank",
  STRIPE_CONNECT_REDIRECT_LONG_URL: APP_LONG_URL + "/settings/income/bank",
  STRIPE_SETUP_INTENT_PARAMS: {
    callback_key: "setup_intent",
    callback_secret_key: "setup_intent_client_secret",
    payment_method_types: ["card"],
    setup_future_usage: "off_session",
    cookie_key: "setup_intent",
    cookie_secret_key: "setup_intent_client_secret",
    cookie_payment_set: "payment_set",
  },
  STRIPE_PAYMENT_INTENT_PARAMS: {
    callback_key: "payment_intent",
    callback_secret_key: "payment_intent_client_secret",
    payment_method_types: ["card"],
    setup_future_usage: "off_session",
    cookie_key: "payment_intent",
    cookie_secret_key: "payment_intent_client_secret",
    cookie_payment_complete: "payment_complete",
    cookie_guest_email: "guest_email",
  },

  // analytics
  DATATDOG_RUM_APP_ID: "326d8c0d-0147-4f0a-bb02-01d55fb995af",
  DATATDOG_RUM_CLIENT_TOKEN: "pub20ea9dcfef8e042a612c36400e5d1d76",
  DATATDOG_RUM_SITE: "datadoghq.eu",
  DATATDOG_RUM_SERVICE: "entzy",
  GOOGLE_TAG_ID: "AW-1034351398",
  GOOGLE_TAG_CONVERSION_SIGNIN: "AW-1034351398/FYHuCPD5m9IZEKbmm-0D",

  // main and external calendars
  CALENDAR_OAUTH_COOKIE: "calendar_oauth",

  // socials
  // should match event-socials service
  SOCIALS: {
    POST_LIMIT_DAYS: configAuto.entzy.ENV === "production" ? 1 : 0,
    POST_REQUEUE_HOURS: configAuto.entzy.ENV === "production" ? 2 : 1,
  },

  // links
  URL_POINTERS: {
    MAIN: POINTER_MAIN,
    BOT: POINTER_BOT,
    BOT_BASIC: POINTER_BOT_BASIC,
    BOT_STYLED: POINTER_BOT_STYLED,
    BOT_STYLED_HELPER: POINTER_BOT_STYLED_HELPER,
    BOT_STYLED_LAUNCHED: POINTER_BOT_STYLED_LAUNCHED,
  },
  URLS: {
    APP: APP_URL,
    APP_POINTER_MAIN: APP_URL + POINTER_MAIN,
    APP_POINTER_BOT_BASIC: APP_URL + POINTER_BOT_BASIC,
    APP_POINTER_BOT_STYLED: APP_URL + POINTER_BOT_STYLED,
  },

  // fallbacks
  EMPTY_STRING_SET: "none:none:none",
  EMPTY_STRING_SHORTSET: "none",

  // products
  PRODUCT_LIST_DEFAULT: "default",
  PRODUCT_CATEGORY_DEFAULT: "default",

  // app strucure
  APP_DRAWER_SIZING: { xs: "90%", sm: "75%", md: "50%", lg: "40%", xl: "40%" },

  // app spacing
  APP_SPACING_XS: {
    xs: BASE_SETTINGS.SPACING_XS.xs + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm + "px",
    md: BASE_SETTINGS.SPACING_XS.md + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg + "px",
  },
  APP_SPACING_SM: {
    xs: BASE_SETTINGS.SPACING_SM.xs + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm + "rem",
    md: BASE_SETTINGS.SPACING_SM.md + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg + "rem",
  },
  APP_SPACING_MD: {
    xs: BASE_SETTINGS.SPACING_MD.xs + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm + "rem",
    md: BASE_SETTINGS.SPACING_MD.md + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg + "rem",
  },
  APP_SPACING_LG: {
    xs: BASE_SETTINGS.SPACING_LG.xs + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm + "rem",
    md: BASE_SETTINGS.SPACING_LG.md + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg + "rem",
  },
  APP_SPACING_XL: {
    xs: BASE_SETTINGS.SPACING_XL.xs + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm + "rem",
    md: BASE_SETTINGS.SPACING_XL.md + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg + "rem",
  },
  APP_SPACING_HL: {
    xs: BASE_SETTINGS.SPACING_HL.xs + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm + "rem",
    md: BASE_SETTINGS.SPACING_HL.md + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg + "rem",
  },
  APP_SPACING_M2L: {
    xs: BASE_SETTINGS.SPACING_M2L.xs + "rem",
    sm: BASE_SETTINGS.SPACING_M2L.sm + "rem",
    md: BASE_SETTINGS.SPACING_M2L.md + "rem",
    lg: BASE_SETTINGS.SPACING_M2L.lg + "rem",
  },

  APP_SPACING_XS2X: {
    xs: BASE_SETTINGS.SPACING_XS.xs * 2 + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm * 2 + "px",
    md: BASE_SETTINGS.SPACING_XS.md * 2 + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg * 2 + "px",
  },
  APP_SPACING_SM2X: {
    xs: BASE_SETTINGS.SPACING_SM.xs * 2 + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm * 2 + "rem",
    md: BASE_SETTINGS.SPACING_SM.md * 2 + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg * 2 + "rem",
  },
  APP_SPACING_MD2X: {
    xs: BASE_SETTINGS.SPACING_MD.xs * 2 + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm * 2 + "rem",
    md: BASE_SETTINGS.SPACING_MD.md * 2 + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg * 2 + "rem",
  },
  APP_SPACING_LG2X: {
    xs: BASE_SETTINGS.SPACING_LG.xs * 2 + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm * 2 + "rem",
    md: BASE_SETTINGS.SPACING_LG.md * 2 + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg * 2 + "rem",
  },
  APP_SPACING_XL2X: {
    xs: BASE_SETTINGS.SPACING_XL.xs * 2 + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm * 2 + "rem",
    md: BASE_SETTINGS.SPACING_XL.md * 2 + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg * 2 + "rem",
  },
  APP_SPACING_HL2X: {
    xs: BASE_SETTINGS.SPACING_HL.xs * 2 + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm * 2 + "rem",
    md: BASE_SETTINGS.SPACING_HL.md * 2 + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg * 2 + "rem",
  },

  APP_SPACING_XS3X: {
    xs: BASE_SETTINGS.SPACING_XS.xs * 3 + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm * 3 + "px",
    md: BASE_SETTINGS.SPACING_XS.md * 3 + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg * 3 + "px",
  },
  APP_SPACING_SM3X: {
    xs: BASE_SETTINGS.SPACING_SM.xs * 3 + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm * 3 + "rem",
    md: BASE_SETTINGS.SPACING_SM.md * 3 + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg * 3 + "rem",
  },
  APP_SPACING_MD3X: {
    xs: BASE_SETTINGS.SPACING_MD.xs * 3 + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm * 3 + "rem",
    md: BASE_SETTINGS.SPACING_MD.md * 3 + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg * 3 + "rem",
  },
  APP_SPACING_LG3X: {
    xs: BASE_SETTINGS.SPACING_LG.xs * 3 + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm * 3 + "rem",
    md: BASE_SETTINGS.SPACING_LG.md * 3 + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg * 3 + "rem",
  },
  APP_SPACING_XL3X: {
    xs: BASE_SETTINGS.SPACING_XL.xs * 3 + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm * 3 + "rem",
    md: BASE_SETTINGS.SPACING_XL.md * 3 + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg * 3 + "rem",
  },
  APP_SPACING_HL3X: {
    xs: BASE_SETTINGS.SPACING_HL.xs * 3 + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm * 3 + "rem",
    md: BASE_SETTINGS.SPACING_HL.md * 3 + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg * 3 + "rem",
  },

  // negative app spacing
  APP_SPACING_NXS: {
    xs: BASE_SETTINGS.SPACING_XS.xs * -1 + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm * -1 + "px",
    md: BASE_SETTINGS.SPACING_XS.md * -1 + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg * -1 + "px",
  },
  APP_SPACING_NSM: {
    xs: BASE_SETTINGS.SPACING_SM.xs * -1 + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm * -1 + "rem",
    md: BASE_SETTINGS.SPACING_SM.md * -1 + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg * -1 + "rem",
  },
  APP_SPACING_NMD: {
    xs: BASE_SETTINGS.SPACING_MD.xs * -1 + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm * -1 + "rem",
    md: BASE_SETTINGS.SPACING_MD.md * -1 + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg * -1 + "rem",
  },
  APP_SPACING_NLG: {
    xs: BASE_SETTINGS.SPACING_LG.xs * -1 + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm * -1 + "rem",
    md: BASE_SETTINGS.SPACING_LG.md * -1 + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg * -1 + "rem",
  },
  APP_SPACING_NXL: {
    xs: BASE_SETTINGS.SPACING_XL.xs * -1 + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm * -1 + "rem",
    md: BASE_SETTINGS.SPACING_XL.md * -1 + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg * -1 + "rem",
  },
  APP_SPACING_NHL: {
    xs: BASE_SETTINGS.SPACING_HL.xs * -1 + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm * -1 + "rem",
    md: BASE_SETTINGS.SPACING_HL.md * -1 + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg * -1 + "rem",
  },

  APP_SPACING_NXS2X: {
    xs: BASE_SETTINGS.SPACING_XS.xs * -2 + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm * -2 + "px",
    md: BASE_SETTINGS.SPACING_XS.md * -2 + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg * -2 + "px",
  },
  APP_SPACING_NSM2X: {
    xs: BASE_SETTINGS.SPACING_SM.xs * -2 + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm * -2 + "rem",
    md: BASE_SETTINGS.SPACING_SM.md * -2 + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg * -2 + "rem",
  },
  APP_SPACING_NMD2X: {
    xs: BASE_SETTINGS.SPACING_MD.xs * -2 + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm * -2 + "rem",
    md: BASE_SETTINGS.SPACING_MD.md * -2 + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg * -2 + "rem",
  },
  APP_SPACING_NLG2X: {
    xs: BASE_SETTINGS.SPACING_LG.xs * -2 + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm * -2 + "rem",
    md: BASE_SETTINGS.SPACING_LG.md * -2 + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg * -2 + "rem",
  },
  APP_SPACING_NXL2X: {
    xs: BASE_SETTINGS.SPACING_XL.xs * -2 + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm * -2 + "rem",
    md: BASE_SETTINGS.SPACING_XL.md * -2 + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg * -2 + "rem",
  },
  APP_SPACING_NHL2X: {
    xs: BASE_SETTINGS.SPACING_HL.xs * -2 + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm * -2 + "rem",
    md: BASE_SETTINGS.SPACING_HL.md * -2 + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg * -2 + "rem",
  },

  APP_SPACING_NXS3X: {
    xs: BASE_SETTINGS.SPACING_XS.xs * -3 + "px",
    sm: BASE_SETTINGS.SPACING_XS.sm * -3 + "px",
    md: BASE_SETTINGS.SPACING_XS.md * -3 + "px",
    lg: BASE_SETTINGS.SPACING_XS.lg * -3 + "px",
  },
  APP_SPACING_NSM3X: {
    xs: BASE_SETTINGS.SPACING_SM.xs * -3 + "rem",
    sm: BASE_SETTINGS.SPACING_SM.sm * -3 + "rem",
    md: BASE_SETTINGS.SPACING_SM.md * -3 + "rem",
    lg: BASE_SETTINGS.SPACING_SM.lg * -3 + "rem",
  },
  APP_SPACING_NMD3X: {
    xs: BASE_SETTINGS.SPACING_MD.xs * -3 + "rem",
    sm: BASE_SETTINGS.SPACING_MD.sm * -3 + "rem",
    md: BASE_SETTINGS.SPACING_MD.md * -3 + "rem",
    lg: BASE_SETTINGS.SPACING_MD.lg * -3 + "rem",
  },
  APP_SPACING_NLG3X: {
    xs: BASE_SETTINGS.SPACING_LG.xs * -3 + "rem",
    sm: BASE_SETTINGS.SPACING_LG.sm * -3 + "rem",
    md: BASE_SETTINGS.SPACING_LG.md * -3 + "rem",
    lg: BASE_SETTINGS.SPACING_LG.lg * -3 + "rem",
  },
  APP_SPACING_NXL3X: {
    xs: BASE_SETTINGS.SPACING_XL.xs * -3 + "rem",
    sm: BASE_SETTINGS.SPACING_XL.sm * -3 + "rem",
    md: BASE_SETTINGS.SPACING_XL.md * -3 + "rem",
    lg: BASE_SETTINGS.SPACING_XL.lg * -3 + "rem",
  },
  APP_SPACING_NHL3X: {
    xs: BASE_SETTINGS.SPACING_HL.xs * -3 + "rem",
    sm: BASE_SETTINGS.SPACING_HL.sm * -3 + "rem",
    md: BASE_SETTINGS.SPACING_HL.md * -3 + "rem",
    lg: BASE_SETTINGS.SPACING_HL.lg * -3 + "rem",
  },

  // avatar structure
  AVATAR_SIZES: ["sm", "md", "lg"], // xs use the sm size image
  AVATAR_SIZE_XS: { xs: "1.5rem", sm: "2.0rem", md: "2.5rem", lg: "2.5rem" },
  AVATAR_SIZE_SM: { xs: "3rem", sm: "4rem", md: "5rem", lg: "5rem" },
  AVATAR_SIZE_MD: { xs: "6rem", sm: "8rem", md: "10rem", lg: "10rem" },
  AVATAR_SIZE_LG: { xs: "12rem", sm: "15rem", md: "18rem", lg: "18rem" },
  AVATAR_SIZE_XL: { xs: "18rem", sm: "24rem", md: "30rem", lg: "30rem" },
  AVATAR_CONTAINER_XS: { xs: "2rem", sm: "2.5rem", md: "3rem", lg: "3.5rem" },
  AVATAR_CONTAINER_SM: { xs: "4rem", sm: "5rem", md: "6rem", lg: "7rem" },
  AVATAR_CONTAINER_MD: { xs: "10rem", sm: "10rem", md: "12rem", lg: "14rem" },
  AVATAR_CONTAINER_LG: { xs: "15rem", sm: "18rem", md: "21rem", lg: "21rem" },
  AVATAR_CONTAINER_XL: { xs: "24rem", sm: "30rem", md: "34rem", lg: "34rem" },
  AVATAR_CONTAINER_XS2X: { xs: "2.5rem", sm: "3rem", md: "3.5rem", lg: "4rem" },
  AVATAR_CONTAINER_SM2X: { xs: "5rem", sm: "6rem", md: "7rem", lg: "8rem" },
  AVATAR_CONTAINER_MD2X: { xs: "10rem", sm: "12rem", md: "14rem", lg: "16rem" },
  AVATAR_CONTAINER_LG2X: { xs: "18rem", sm: "21rem", md: "24rem", lg: "24rem" },
  AVATAR_CONTAINER_XL2X: { xs: "30rem", sm: "34rem", md: "38rem", lg: "38rem" },

  // font structure
  FONT_SCALE_SM: { xs: "0.7rem", sm: "0.9rem", md: "1rem", lg: "1rem" },
  FONT_SCALE_MD: { xs: "0.8rem", sm: "1rem", md: "1.2rem", lg: "1.2rem" },
  FONT_SCALE_LG: { xs: "0.9rem", sm: "1.2rem", md: "1.5rem", lg: "1.5rem" },

  // border structure
  BORDER_SIZE_SM: {
    xs: "1px",
    sm: "1px",
    md: "2px",
    lg: "2px",
    xl: "3px",
  },
  BORDER_SIZE_MD: {
    xs: "2px",
    sm: "3px",
    md: "4px",
    lg: "5px",
    xl: "6px",
  },
  BORDER_SIZE_LG: {
    xs: "4px",
    sm: "6px",
    md: "8px",
    lg: "10px",
    xl: "12px",
  },
  BORDER_SIZE_XL: {
    xs: "8px",
    sm: "10px",
    md: "12px",
    lg: "14px",
    xl: "16px",
  },
  BORDER_DASH_WSM: {
    xs: "1px dashed #fff",
    sm: "1px dashed #fff",
    md: "2px dashed #fff",
    lg: "2px dashed #fff",
    xl: "3px dashed #fff",
  },
  BORDER_DASH_WMD: {
    xs: "2px dashed #fff",
    sm: "3px dashed #fff",
    md: "4px dashed #fff",
    lg: "5px dashed #fff",
    xl: "6px dashed #fff",
  },
  BORDER_DASH_WLG: {
    xs: "4px dashed #fff",
    sm: "6px dashed #fff",
    md: "8px dashed #fff",
    lg: "10px dashed #fff",
    xl: "12px dashed #fff",
  },
  BORDER_DASH_GSM: {
    xs: "1px dashed #333",
    sm: "1px dashed #333",
    md: "2px dashed #333",
    lg: "2px dashed #333",
    xl: "3px dashed #333",
  },
  BORDER_DASH_GMD: {
    xs: "2px dashed #333",
    sm: "3px dashed #333",
    md: "4px dashed #333",
    lg: "5px dashed #333",
    xl: "6px dashed #333",
  },
  BORDER_DASH_GLG: {
    xs: "4px dashed #333",
    sm: "6px dashed #333",
    md: "8px dashed #333",
    lg: "10px dashed #333",
    xl: "12px dashed #333",
  },
  BORDER_DASH_OSM: {
    xs: "1px dashed #f90",
    sm: "1px dashed #f90",
    md: "2px dashed #f90",
    lg: "2px dashed #f90",
    xl: "3px dashed #f90",
  },
  BORDER_DASH_OMD: {
    xs: "2px dashed #f90",
    sm: "3px dashed #f90",
    md: "4px dashed #f90",
    lg: "5px dashed #f90",
    xl: "6px dashed #f90",
  },
  BORDER_DASH_OLG: {
    xs: "4px dashed #f90",
    sm: "6px dashed #f90",
    md: "8px dashed #f90",
    lg: "10px dashed #f90",
    xl: "12px dashed #f90",
  },
  BORDER_DASH_DSM: {
    xs: "1px dashed #ccc",
    sm: "1px dashed #ccc",
    md: "2px dashed #ccc",
    lg: "2px dashed #ccc",
    xl: "3px dashed #ccc",
  },
  BORDER_DASH_DMD: {
    xs: "2px dashed #ccc",
    sm: "3px dashed #ccc",
    md: "4px dashed #ccc",
    lg: "5px dashed #ccc",
    xl: "6px dashed #ccc",
  },
  BORDER_DASH_DLG: {
    xs: "4px dashed #ccc",
    sm: "6px dashed #ccc",
    md: "8px dashed #ccc",
    lg: "10px dashed #ccc",
    xl: "12px dashed #ccc",
  },

  // notifications
  NOTIFICATION_EXPIRY_DAYS: 365,
  DEVICE_SYNC_STAMP: {
    LENGTHS: [3, 8, 6],
    DELIMITERS: [":", "|"],
    MAX_CONCAT_LENGTH: 100,
    generateStamp: function (parts) {
      // generates a fixed width 20 chars device shortid tracker (platform:token:YYMMDD)
      let stamp = {
        platform: "0".repeat(this.LENGTHS[0]),
        token: "0".repeat(this.LENGTHS[1]),
        date: "1".repeat(this.LENGTHS[2]), // YYMMDD default yields valid date 111111
      };
      if (parts) {
        if (parts.platform) {
          stamp.platform = (
            stamp.platform + parts.platform.slice(0, this.LENGTHS[0])
          ).slice(this.LENGTHS[0] * -1);
        }
        if (parts.token) {
          stamp.token = (stamp.token + parts.token).slice(this.LENGTHS[1] * -1);
        }
        if (parts.date) {
          stamp.date = (stamp.date + parts.date).slice(this.LENGTHS[2] * -1);
        }
      }
      stamp.result =
        stamp.platform +
        this.DELIMITERS[0] +
        stamp.token +
        this.DELIMITERS[0] +
        stamp.date +
        this.DELIMITERS[1];
      return stamp.result;
    },
  },
};

export default CONFIG_ENTZY;
