// react core
import { Fragment, useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Offline } from "react-detect-offline";

// aws tools
import configAws from "components/config/ConfigAws";
import { Auth, Hub, Storage, API } from "aws-amplify";

// stripe tools
import { loadStripe } from "@stripe/stripe-js";

// datadog tools
import { datadogRum } from "@datadog/browser-rum";

// material theme
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// material date picker
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// material design
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// entzy models and services
import { APP_MENU_TREE } from "models/Structure";
import {
  serviceActionCapture,
  serviceActionListGet,
  // serviceActionListClear,
  serviceSyncUser,
  serviceSyncDevice,
  serviceJoinInvite,
  serviceBackdropGet,
  serviceBackdropSet,
  serviceCookieGet,
  serviceCookieSet,
  servicePaymentMethodSet,
  servicePaymentConfirm,
} from "services/graphql/call";
import { AppLoader, ActionLoader } from "components/utils/common/CommonLoaders";

// entzy components
import configEntzy from "components/config/ConfigEntzy";
import MainHeader from "components/main/MainHeader";
import MainContent from "components/main/MainContent";
import MenuBox from "components/menu/MenuBox";
import ConnectBox from "components/connect/ConnectBox";
import { scrollSnapTop } from "models/Tools";

// entzy hooks
import { useIdentityState } from "hooks/identity/identityState";
import { Typography } from "@mui/material";
import { ActionBoxButton } from "components/utils/common/CommonButtons";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp as iconYes } from "@fortawesome/pro-duotone-svg-icons";
import { faThumbsDown as iconNo } from "@fortawesome/pro-duotone-svg-icons";

// intercept amplify auth responses to handle in-app oauth scope requests
const _handleAuthResponse = Auth._handleAuthResponse.bind(Auth);
Auth._handleAuthResponse = (url) => {
  // check for oauth code related params in url
  // anything not login related handle via cookie actions
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);
  if (searchParams.has("code") && searchParams.has("scope")) {
    let code, scope, provider;
    code = searchParams.get("code");
    scope = searchParams.get("scope");
    // google calendar
    if (scope.includes("https://www.googleapis.com/auth/calendar")) {
      provider = "google";
      serviceCookieSet(
        configEntzy.CALENDAR_OAUTH_COOKIE,
        JSON.stringify({ code, provider }),
        0
      );
      return;
    }
  }
  // standard amplify response handling
  return _handleAuthResponse(url);
};

Auth.configure(configAws.Auth);
Storage.configure(configAws.Storage.read);
API.configure(configAws.API);

Hub.listen("auth", ({ payload: { event, data } }) => {
  switch (event) {
    case "signIn":
      serviceSyncUser(event);
      scrollSnapTop();

      // google conversion tracking
      if (serviceCookieGet("cookieConsent") !== "reject") {
        if (window && window.gtag) {
          window.gtag("event", "conversion", {
            send_to: configEntzy.GOOGLE_TAG_CONVERSION_SIGNIN,
          });
        }
      }

      break;
    default:
      break;
  }
});

// data dog realtime user monitoring
if (serviceCookieGet("cookieConsent") !== "reject") {
  datadogRum.init({
    applicationId: configEntzy.DATATDOG_RUM_APP_ID,
    clientToken: configEntzy.DATATDOG_RUM_CLIENT_TOKEN,
    site: configEntzy.DATATDOG_RUM_SITE,
    service: configEntzy.DATATDOG_RUM_SERVICE,
    env: configEntzy.APP_ENV,
    version: configEntzy.APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });
}

function appSections() {
  return {
    header: "header",
    menu: "menu",
    connect: "connect",
    content: "content",
  };
}

function appAlertStandBy() {
  return { show: false, message: "" };
}
function autoJoinEventStandBy() {
  return { EventId: null };
}

function App(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const [identifying, user, userReload] = useIdentityState();
  const [drawerMenu, setDrawerMenu] = useState(false);
  const [drawerConnect, setDrawerConnect] = useState(false);
  const [menuSelected, setMenuSelected] = useState(
    APP_MENU_TREE.filter((obj) => obj.nav === props.page)[0]
  );
  const [reloadKeys, setReloadKeys] = useState(() => appSections());
  const [appAlert, setAppAlert] = useState(appAlertStandBy());
  const [autoJoinEvent, setAutoJoinEvent] = useState(null);
  const [backdropSelect, setBackdropSelect] = useState(null);
  const [backdropActive, setBackdropActive] = useState(null);
  const [actionLoading, setActionLoading] = useState(0);

  const [cookieConsent, setCookieConsent] = useState(null);
  const [refreshCookieConsent, setRefreshCookieConsent] = useState(null);

  const handleAppAlertClear = () => {
    setAppAlert(appAlertStandBy());
  };
  const handleAutoJoinEventClear = () => {
    setAutoJoinEvent(autoJoinEventStandBy());
  };

  const drawerMenuToggle = (state) => {
    setDrawerMenu(typeof state == "boolean" ? state : !drawerMenu);
  };
  const drawerConnectToggle = (state) => {
    setDrawerConnect(typeof state == "boolean" ? state : !drawerConnect);
  };

  const connectionStart = (connecting) => {
    navigate("/connecting?flow=" + connecting.split(":")[1]);
  };
  const menuSelect = (selection) => {
    if (selection.id === "menu") {
      drawerMenuToggle(true);
    } else if (selection.id === "connect") {
      drawerConnectToggle(true);
    } else {
      setMenuSelected(selection);
      navigate(selection.nav);
      drawerMenuToggle(false);
      if (selection.parent) {
        scrollSnapTop();
      }
    }
    sectionReload(["header"]);
  };
  const menuSelectById = (id) => {
    // check for external connection flows
    if (id.startsWith("connecting:")) {
      return connectionStart(id);
    } else {
      const selection = APP_MENU_TREE.filter((obj) => obj.id === id)[0];
      return menuSelect(selection);
    }
  };

  const sectionReload = (sections) => {
    const newKeys = appSections();
    for (let section of sections) {
      newKeys[section] = section + Date.now();
    }
    setReloadKeys(newKeys);
  };
  const sectionUserReload = async () => {
    sectionReload(["header", "menu", "connect", "content"]);
    await userReload();
  };
  const cbSectionUserReload = useCallback(sectionUserReload, [userReload]);

  const setBackdrop = (value) => {
    const backdrop = serviceBackdropSet(value);
    setBackdropSelect(backdrop);
  };

  const handleCookieConsent = (value) => {
    serviceCookieSet("cookieConsent", value, 365);
    setRefreshCookieConsent(value);
  };

  // check for cookie consent
  useEffect(() => {
    const cookieConsentState = serviceCookieGet("cookieConsent");
    setCookieConsent(cookieConsentState);
    if (window && window.gtag) {
      if (cookieConsentState === "reject") {
        window.gtag("consent", "default", {
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          analytics_storage: "denied",
        });
      } else {
        window.gtag("consent", "update", {
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "granted",
          analytics_storage: "granted",
        });
      }
    }
  }, [refreshCookieConsent]);

  // listen for top level app actions
  useEffect(() => {
    const queryStringActionCapture = async (action) => {
      switch (action.type) {
        case "push":
          action.platform = decodeURIComponent(searchParams.get("platform"));
          action.token = decodeURIComponent(searchParams.get("token"));
          action.status = await serviceActionCapture(action);
          break;
        case "join":
          action.eid = decodeURIComponent(searchParams.get("eid"));
          action.token = decodeURIComponent(searchParams.get("token"));
          action.status = await serviceActionCapture(action);
          // flag an info alert if user collecting invite before connecting
          if (!user.connected) {
            setAppAlert({
              show: true,
              severity: "info",
              title: "Invite Ready",
              message:
                "Your invite is ready to be accepted. To continue please connect with your preferred provider using the navigation at the top.",
            });
          }
          break;
        case configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key:
          action.setupIntentId = decodeURIComponent(
            searchParams.get(
              configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key
            )
          );
          action.clientSecret = decodeURIComponent(
            searchParams.get(
              configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_secret_key
            )
          );
          action.status = await serviceActionCapture(action);
          break;
        case configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key:
          action.paymentIntentId = decodeURIComponent(
            searchParams.get(
              configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key
            )
          );
          action.clientSecret = decodeURIComponent(
            searchParams.get(
              configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_secret_key
            )
          );
          action.status = await serviceActionCapture(action);
          break;
        default:
          action.status = {
            success: false,
            alert: false,
            message: "Invalid action type",
          };
          break;
      }
      // check results and execute immediately if user connected
      let result = [action];
      let executeNow =
        user.connected ||
        [configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key].includes(
          action.type
        );
      if (!action.alert && executeNow) {
        result = await queryStringActionListExecute();
      }
      return result;
    };
    const queryStringActionListExecute = async () => {
      const actionList = serviceActionListGet();
      // action loader handling
      // needs to be separate from processing as causes re-render
      if (actionLoading === 0 && actionList.length > 0) {
        let loader = 1;
        for (let action of actionList) {
          if (
            [
              configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key,
              configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key,
            ].includes(action.type)
          ) {
            loader = 2;
            break;
          }
        }
        setActionLoading(loader);
      }
      // action processing start
      if (actionLoading > 0 && actionList.length > 0) {
        // first clear the action cookie list to avoid re-execution
        // serviceActionListClear(); // does not work on ios
        serviceCookieSet("actionList", "[]", 0);
        // process actions
        let alertSeverity;
        let alertMessage = "";
        let autoJoinEvent;
        for (let action of actionList) {
          switch (action.type) {
            case "push":
              action.status = await serviceSyncDevice(user, action);
              break;
            case "join":
              action.status = await serviceJoinInvite(user, action);
              break;
            case "redirect":
              action.status = { success: true, alert: false };
              navigate(action.path);
              break;
            case configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key:
              action.stripe = await loadStripe(
                configEntzy.APP_PAYMENTS_PUBLIC_KEY
              );
              action.status = await servicePaymentMethodSet(user, action);
              await cbSectionUserReload();
              break;
            case configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key:
              action.stripe = await loadStripe(
                configEntzy.APP_PAYMENTS_PUBLIC_KEY
              );
              action.status = await servicePaymentConfirm(user, action);
              break;
            default:
              action.status = {
                success: false,
                alert: false,
                message: "Invalid action type",
              };
              break;
          }
          if (action.status.alert) {
            alertMessage += action.status.message + " ";
            if (action.status.success && alertSeverity !== "error") {
              alertSeverity = "info";
            } else {
              alertSeverity = "error";
            }
          }
          if (action.status.autoJoinEvent) {
            autoJoinEvent = action.status.autoJoinEvent;
          }
        }
        if (alertSeverity) {
          setAppAlert({
            show: true,
            severity: alertSeverity,
            message: alertMessage,
          });
        }
        // action loader handling
        setActionLoading(-1);
        // any post processing actions
        if (autoJoinEvent) {
          setAutoJoinEvent(autoJoinEvent);
        }
      }
      return actionList;
    };
    const queryStringClear = () => {
      for (let item of searchParams) {
        searchParams.delete(item.key);
      }
      // navigate(window.location.pathname);
      navigate(location.pathname);
    };
    // check for path and querystring actions
    if (!identifying && user) {
      // check for custom oauth code related actions
      if (searchParams.has("code") && searchParams.has("scope")) {
        const scope = searchParams.get("scope");
        if (scope.includes("https://www.googleapis.com/auth/calendar")) {
          const path = location.pathname;
          navigate(path);
        }
      }
      // check for direct user profile requests and redirect /@name to /@/name
      if (
        location.pathname.startsWith("/@") &&
        !location.pathname.startsWith("/@/")
      ) {
        const path = location.pathname.replace("/@", "/@/");
        navigate(path);
      }
      if (searchParams.has("action")) {
        // capture actions and reset querystring when received
        const action = {
          type: searchParams.get("action"),
        };
        queryStringClear();
        setTimeout(() => {
          queryStringActionCapture(action);
        }, 500);
      } else if (
        searchParams.has(configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key)
      ) {
        const action = {
          type: configEntzy.STRIPE_SETUP_INTENT_PARAMS.callback_key,
        };
        queryStringClear();
        setTimeout(() => {
          queryStringActionCapture(action);
        }, 500);
      } else if (
        searchParams.has(configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key)
      ) {
        const action = {
          type: configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.callback_key,
        };
        queryStringClear();
        setTimeout(() => {
          queryStringActionCapture(action);
        }, 500);
      } else {
        if (user.connected) {
          // check for and invoke actions when user connected
          queryStringActionListExecute();
        }
      }
    }
  }, [
    identifying,
    user,
    actionLoading,
    searchParams,
    location,
    navigate,
    cbSectionUserReload,
  ]);

  useEffect(() => {
    const changeBodyClass = () => {
      let backdrop;
      if (backdropSelect) {
        backdrop = backdropSelect;
      } else {
        backdrop = serviceBackdropGet();
      }
      document.body.className = backdrop;
      setBackdropActive(backdrop);
    };
    changeBodyClass();

    return () => {
      // document.body.className = '';
    };
  }, [backdropSelect]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
        <CssBaseline />
        <Box id="anchor-main-test" className="box-default"></Box>
        <Box
          id="anchor-main-app"
          className="box-default full-height bg-black-t75"
        >
          {identifying || actionLoading === 2 ? (
            <AppLoader />
          ) : (
            <Box className="box-default">
              <Offline
                polling={{
                  url: configEntzy.APP_URL + "/health",
                  interval: 10000,
                  timeout: 5000,
                }}
              >
                <Box
                  className="box-default bg-black-t50"
                  sx={{ p: configEntzy.APP_SPACING_SM }}
                >
                  <Typography variant="subtitle1" color="white">
                    Check Internet Connection
                  </Typography>
                </Box>
              </Offline>

              <MainHeader
                key={reloadKeys.header}
                page={props.page}
                drawerMenuToggle={drawerMenuToggle}
                drawerConnectToggle={drawerConnectToggle}
                sectionReload={sectionReload}
                menuSelect={menuSelect}
                menuSelectById={menuSelectById}
                menuSelected={menuSelected}
                user={user}
                userReload={sectionUserReload}
              />

              {
                // show this if want to check for actions loading
                actionLoading === 1 && (
                  <Box className="box-default zero-height">
                    <Box
                      className="box-fixed bottom-left full-width"
                      sx={{ pb: configEntzy.APP_SPACING_MD2X }}
                      hidden={true}
                    >
                      <ActionLoader size="sm" />
                    </Box>
                  </Box>
                )
              }

              <MainContent
                key={reloadKeys.content}
                page={props.page}
                hideMenu={props.hideMenu}
                searchParams={searchParams}
                navigate={navigate}
                drawerMenu={drawerMenu}
                drawerConnect={drawerConnect}
                drawerMenuToggle={drawerMenuToggle}
                drawerConnectToggle={drawerConnectToggle}
                sectionReload={sectionReload}
                menuSelect={menuSelect}
                menuSelectById={menuSelectById}
                menuSelected={menuSelected}
                user={user}
                userReload={sectionUserReload}
                appAlert={appAlert}
                appAlertClear={handleAppAlertClear}
                autoJoinEvent={autoJoinEvent}
                autoJoinEventClear={handleAutoJoinEventClear}
              />

              <Fragment>
                <Drawer
                  anchor="left"
                  open={drawerMenu}
                  className="custom-scrollbar"
                  onClose={() => setDrawerMenu(false)}
                  PaperProps={{
                    sx: {
                      width: configEntzy.APP_DRAWER_SIZING,
                    },
                  }}
                >
                  <MenuBox
                    key={reloadKeys.menu}
                    navigate={navigate}
                    drawerMenuToggle={drawerMenuToggle}
                    drawerConnectToggle={drawerConnectToggle}
                    sectionReload={sectionReload}
                    menuSelect={menuSelect}
                    menuSelectById={menuSelectById}
                    menuSelected={menuSelected}
                    backdropActive={backdropActive}
                    setBackdrop={setBackdrop}
                    user={user}
                    userReload={sectionUserReload}
                  />
                </Drawer>

                <Drawer
                  anchor="right"
                  open={drawerConnect}
                  onClose={() => setDrawerConnect(false)}
                  PaperProps={{
                    sx: {
                      width: configEntzy.APP_DRAWER_SIZING,
                    },
                  }}
                >
                  <Box
                    className="box-default"
                    role="presentation"
                    onClick={() => drawerConnectToggle}
                  >
                    <ConnectBox
                      key={reloadKeys.connect}
                      navigate={navigate}
                      drawerConnectToggle={drawerConnectToggle}
                      sectionReload={sectionReload}
                      menuSelectById={menuSelectById}
                      user={user}
                      userReload={sectionUserReload}
                    />
                  </Box>
                </Drawer>
              </Fragment>
            </Box>
          )}
          {/* cookie consent */}
          {!identifying && !user.connected && (
            <Box className="box-default">
              {cookieConsent ? (
                <Box
                  className="box-fixed bottom"
                  sx={{
                    pb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="top"
                        control={
                          <Switch
                            color="success"
                            checked={cookieConsent === "accept"}
                            onChange={(event) => {
                              handleCookieConsent(
                                event.target.checked ? "accept" : "reject"
                              );
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="subtitle2"
                            color={
                              cookieConsent === "accept" ? "success" : "white"
                            }
                          >
                            Cookies
                          </Typography>
                        }
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              ) : (
                <Box
                  className="box-fixed bottom full-width bg-black pop-top"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Container maxWidth="lg">
                    <Box className="box-default">
                      <Box
                        className="box-default"
                        sx={{
                          pr: configEntzy.APP_SPACING_SM,
                          width: "20%",
                        }}
                      >
                        <ActionBoxButton
                          size="small"
                          text={
                            <FontAwesomeIcon
                              icon={iconNo}
                              transform="grow-10"
                              fixedWidth
                            />
                          }
                          bgColor="danger.light"
                          onClick={() => handleCookieConsent("reject")}
                        />
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pt: configEntzy.APP_SPACING_SM,
                          width: "60%",
                        }}
                      >
                        <Typography variant="body2">
                          Performance cookies ok?
                        </Typography>
                        <Typography variant="body2">
                          Helps us make the app awesome
                        </Typography>
                      </Box>
                      <Box
                        className="box-default"
                        sx={{
                          pl: configEntzy.APP_SPACING_SM,
                          width: "20%",
                        }}
                      >
                        <ActionBoxButton
                          size="small"
                          text={
                            <FontAwesomeIcon
                              icon={iconYes}
                              transform="grow-10"
                              fixedWidth
                            />
                          }
                          bgColor="success.light"
                          onClick={() => handleCookieConsent("accept")}
                        />
                      </Box>
                    </Box>
                  </Container>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
