// react core
import { useState, Fragment } from "react";

// material design
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";

// fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessageCaptions as iconMessages } from "@fortawesome/pro-thin-svg-icons";
import { faRocketLaunch as iconActvity } from "@fortawesome/pro-thin-svg-icons";
import { faBan as iconDisabled } from "@fortawesome/pro-duotone-svg-icons";
// import { faInfoCircle as iconInfo } from "@fortawesome/pro-solid-svg-icons";
// import { faRectangleWide as iconBar } from "@fortawesome/pro-solid-svg-icons";
// import { faCopy as iconCopy } from "@fortawesome/pro-regular-svg-icons";
// import { faCheck as iconConfirm } from "@fortawesome/pro-regular-svg-icons";

// material icons
import IconButton from "@mui/material/IconButton";
import CopyIcon from "@mui/icons-material/CopyAllTwoTone";
import ConfirmIcon from "@mui/icons-material/CheckTwoTone";
// import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
// import ShareIcon from "@mui/icons-material/ShareTwoTone";

// entzy config and services
import configEntzy from "components/config/ConfigEntzy";
import { TextTag } from "components/utils/common/CommonTags";
// import { ProfileAvatar } from "components/utils/common/CommonAvatars";
import { ActionLoader } from "components/utils/common/CommonLoaders";
import { useMediaImage } from "hooks/media/mediaImage";
import { Grid } from "@mui/material";

export function BigButton(props) {
  return (
    <CardActionArea
      onClick={props.onClick}
      sx={{ borderRadius: configEntzy.BORDER_SIZE_LG }}
    >
      <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_SM }}>
        <Box
          className={
            "box-default" +
            (props.bgColor ? props.bgColor + "" : " bg-black-t50")
          }
          sx={{
            pt:
              props.size === "sm"
                ? configEntzy.APP_SPACING_MD
                : props.size === "md"
                ? configEntzy.APP_SPACING_MD
                : configEntzy.APP_SPACING_LG,
            pb:
              props.size === "sm"
                ? configEntzy.APP_SPACING_MD
                : props.size === "md"
                ? configEntzy.APP_SPACING_MD
                : configEntzy.APP_SPACING_LG,
            borderRadius: configEntzy.BORDER_SIZE_XL,
            backgroundColor: props.bgColor ? props.bgColor : undefined,
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant={
                  props.size === "sm"
                    ? "subtitle2"
                    : props.size === "md"
                    ? "subtitle2"
                    : "h6"
                }
              >
                &nbsp;
              </Typography>
              {props.avatar ? (
                <Box className="box-default">
                  <Box className="box-inline">{props.avatar}</Box>
                </Box>
              ) : (
                <Typography
                  variant={
                    props.size === "sm"
                      ? "subtitle2"
                      : props.size === "md"
                      ? "subtitle2"
                      : "h6"
                  }
                  sx={{
                    color: props.light ? "white" : "black",
                  }}
                >
                  <span className="fa-layers fa-fw">
                    {props.iconLayer && (
                      <Fragment>
                        <FontAwesomeIcon
                          icon={props.iconLayer}
                          color="black"
                          transform="grow-20 right-25"
                        />
                      </Fragment>
                    )}
                    <FontAwesomeIcon
                      icon={props.icon}
                      transform={
                        props.iconLayer ? "grow-30 left-25" : "grow-30"
                      }
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                        color: props.iconColor ? props.iconColor : "white",
                      }}
                      fixedWidth
                    />
                  </span>
                </Typography>
              )}
            </Grid>
            {props.text && (
              <Grid item xs={12}>
                <Typography
                  variant={
                    props.size === "sm"
                      ? "subtitle2"
                      : props.size === "md"
                      ? "subtitle2"
                      : "h6"
                  }
                  sx={{
                    color: props.textColor ? props.textColor : "white",
                    mt:
                      props.size === "sm"
                        ? configEntzy.APP_SPACING_MD2X
                        : props.size === "md"
                        ? configEntzy.APP_SPACING_MD2X
                        : configEntzy.APP_SPACING_MD3X,
                  }}
                >
                  {props.text}
                </Typography>
              </Grid>
            )}
            {props.subText && (
              <Grid item xs={12}>
                <Typography
                  variant={
                    props.size === "sm"
                      ? "subtitle1"
                      : props.size === "md"
                      ? "subtitle1"
                      : "subtitle2"
                  }
                  sx={{
                    color: props.subTextColor ? props.subTextColor : "orange",
                  }}
                >
                  <em>{props.subText}</em>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </CardActionArea>
  );
}

export function ActionButton(props) {
  let fontSize, spacing;
  switch (props.size) {
    case "small":
      fontSize = configEntzy.FONT_SCALE_SM;
      spacing = configEntzy.APP_SPACING_SM2X;
      break;
    case "large":
      fontSize = configEntzy.FONT_SCALE_LG;
      spacing = configEntzy.APP_SPACING_MD2X;
      break;
    default:
      fontSize = configEntzy.FONT_SCALE_MD;
      spacing = configEntzy.APP_SPACING_MD;
      break;
  }

  return (
    <Button
      type="button"
      variant={props.variant ? props.variant : "contained"}
      className={props.borderGlow ? "border-glow" : ""}
      component={props.component}
      id={props.id}
      name={props.name}
      color={props.color}
      size={props.size}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      disabled={props.disabled}
      disableElevation={props.disableElevation}
      sx={{
        fontSize: fontSize,
        p: spacing,
        textTransform: "none",
        justifyContent: props.textLeft ? "flex-start" : undefined,
        boxShadow:
          props.disableElevation && props.color === "bright"
            ? "rgba(0, 0, 0, 0.12) 0px 0.8px 0.8px, rgba(0, 0, 0, 0.24) 0px 0.8px 0.8px"
            : undefined,
        border: props.border
          ? "1px solid " + (props.borderColor ? props.borderColor : "#333")
          : undefined,
      }}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
    >
      <Typography
        variant="inherit"
        className={props.textNoCasing ? "inherit" : "upper-case"}
        noWrap={true}
      >
        {props.textLeft && <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>}
        <span>&nbsp;&nbsp;{props.text}&nbsp;&nbsp;</span>
        {props.disabled && (
          <span>
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={iconDisabled} color="purple" />
          </span>
        )}
      </Typography>
    </Button>
  );
}

export function ActionBoxButton(props) {
  const textVariant =
    props.size === "small"
      ? "body2"
      : props.size === "large"
      ? "h6"
      : "subtitle2";
  // legacy support
  const subText = props.subText
    ? props.subText
    : props.subtext
    ? props.subtext
    : null;
  return (
    <Box
      className={
        "box-default " +
        // (props.bgColor
        //   ? props.bgColor + " "
        //   : props.dark
        //   ? "bg-black-t10 "
        //   : "bg-white ") +
        (props.disabled
          ? ""
          : props.selected
          ? "shadow-selected"
          : props.disableElevation
          ? "shadow-light action-pointer"
          : "shadow-default action-pointer") +
        (props.borderGlow ? " border-glow" : "")
      }
      sx={{
        p: configEntzy.APP_SPACING_MD,
        width: props.circular
          ? props.size === "large"
            ? configEntzy.AVATAR_SIZE_LG
            : configEntzy.AVATAR_SIZE_MD
          : undefined,
        height: props.fullHeight
          ? "100%"
          : props.circular
          ? props.size === "large"
            ? configEntzy.AVATAR_SIZE_LG
            : configEntzy.AVATAR_SIZE_MD
          : undefined,
        border: props.borderColor
          ? props.size === "large"
            ? "2px solid " + props.borderColor
            : "1px solid " + props.borderColor
          : "none",
        borderRadius: props.circular ? "50%" : configEntzy.BORDER_SIZE_XL,
        backgroundColor: props.disabled
          ? "gray"
          : props.bgColor
          ? props.bgColor
          : "white",
        color: props.disabled ? "#a0a0a0" : props.color ? props.color : "black",
        display: "flex",
        alignItems: "center",
        opacity: props.disabled ? 0.4 : props.bgColor ? 0.9 : 1,
      }}
      onClick={props.disabled ? undefined : props.onClick}
    >
      <Box className="box-default">
        {props.topIcon && (
          <Box className="box-default">
            <Typography
              variant={textVariant}
              className="upper-case"
              noWrap={true}
              sx={{
                mb: configEntzy.APP_SPACING_MD,
                overflow: "visible",
              }}
            >
              <span>{props.topIcon}</span>
            </Typography>
          </Box>
        )}

        <Box className="box-default" sx={{ height: "0px" }}>
          <Box className="box-inline text-left" sx={{ width: "10%" }}>
            <Typography
              variant={textVariant}
              className="upper-case overflow"
              noWrap={true}
            >
              {props.startIcon ? (
                <span>{props.startIcon}</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Typography>
          </Box>
          <Box className="box-inline text-center" sx={{ width: "80%" }}>
            <Typography variant={textVariant} className="upper-case">
              <span>&nbsp;&nbsp;</span>
            </Typography>
          </Box>
          <Box className="box-inline text-right" sx={{ width: "10%" }}>
            <Typography
              variant={textVariant}
              className="upper-case overflow"
              noWrap={true}
            >
              {props.endIcon ? (
                <span>{props.endIcon}</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </Typography>
          </Box>
        </Box>

        {props.disabled && (
          <Box
            className={
              "box-default zero-height" +
              (props.endIcon
                ? " text-right"
                : props.startIcon
                ? " text-left"
                : props.text
                ? " text-left"
                : " text-center")
            }
          >
            <Typography variant={textVariant} noWrap={true}>
              <span>
                &nbsp;&nbsp;
                <FontAwesomeIcon
                  icon={iconDisabled}
                  color="purple"
                  transform="grow-4"
                />
              </span>
            </Typography>
          </Box>
        )}

        <Box className="box-default">
          <Typography
            variant={textVariant}
            className={props.textNoCasing ? "inherit" : "upper-case"}
            noWrap={true}
          >
            {props.startCompactIcon && (
              <span>{props.startCompactIcon}&nbsp;&nbsp;</span>
            )}
            {props.text}
            {props.endCompactIcon && (
              <span>&nbsp;&nbsp;{props.endCompactIcon}</span>
            )}
          </Typography>
        </Box>

        {subText && (
          <Box className="box-default">
            <Typography
              variant={textVariant}
              noWrap={true}
              sx={{
                // mt: configEntzy.APP_SPACING_SM,
                // mb: configEntzy.APP_SPACING_SM,
                fontWeight: 300,
              }}
            >
              <em>
                <span>{subText}</span>
              </em>
            </Typography>
          </Box>
        )}

        {props.bottomIcon && (
          <Box className="box-default">
            <Typography
              variant={textVariant}
              className="upper-case"
              noWrap={true}
              sx={{
                mt: configEntzy.APP_SPACING_MD,
                overflow: "visible",
              }}
            >
              <span>{props.bottomIcon}</span>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export function MoreButton(props) {
  return (
    <Fragment>
      {props.loading ? (
        <ActionLoader />
      ) : (
        <Button
          variant={props.variant ? props.variant : "contained"}
          type="button"
          color={props.color}
          size={props.size}
          onClick={props.onClick}
          disabled={props.disabled}
          sx={{ fontSize: configEntzy.FONT_SCALE_MD }}
          startIcon={props.startIcon}
          endIcon={props.endIcon}
        >
          &nbsp;{props.text}&nbsp;
        </Button>
      )}
    </Fragment>
  );
}

export function EventButton(props) {
  // load page images
  const eventLogo = useMediaImage({
    user: props.user,
    context: props.mainContext,
    image: {
      id: "event:logo:" + props.event.EventId,
      key: props.event.Logo,
    },
  });

  return (
    <Box className="box-default">
      {props.archiving && props.archiving.EventId === props.event.EventId && (
        <Box className="box-default zero-height text-center">
          <Box
            className="box-inline bg-white shadow-default"
            sx={{
              mt: configEntzy.APP_SPACING_MD,
              pl: configEntzy.APP_SPACING_XL,
              pr: configEntzy.APP_SPACING_XL,
              pt: configEntzy.APP_SPACING_MD,
              pb: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_XL,
              backgroundColor: props.isEventArchived(props.event)
                ? "white"
                : "secondary.main",
              opacity: 0.9,
            }}
          >
            <ActionLoader size="md" color="secondary" />
          </Box>
        </Box>
      )}
      {/* Event Banner Logo Wrapper */}
      <Box className="box-default relative transition-children">
        <Box
          className="box-default"
          sx={{
            "&::before": {
              content: `''`,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundImage: eventLogo.ready
                ? "url(" +
                  (eventLogo.hydrated
                    ? eventLogo.images.md.data
                    : eventLogo.fallback) +
                  ")"
                : null,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              borderRadius: configEntzy.BORDER_SIZE_LG,
              opacity: 0.8,
              // opacity: eventLogo.ready ? 0.4 : 0,
              // transition: "opacity 0.5s ease-in",
            },
          }}
        >
          <Box
            className={
              "box-default relative action-pointover" +
              (props.isEventArchived(props.event)
                ? " bg-black"
                : eventLogo.hydrated
                ? " tranparent shadow-default"
                : " bg-white-t50 shadow-default")
            }
            sx={{
              p: configEntzy.APP_SPACING_MD,
              borderRadius: configEntzy.BORDER_SIZE_LG,
              opacity: props.isEventArchived(props.event) ? 0.5 : 1,
              zIndex: 1,
            }}
          >
            {/* ----- main event opener boundary ----- */}
            <Box
              className="box-default"
              onClick={() =>
                props.onClick(props.event.EventId, props.event.Url)
              }
            >
              <Grid container>
                <Grid item xs={3}>
                  <Box className="box-default text-left">
                    <Typography variant="h6">
                      <span className="fa-layers fa-fw fa-2x">
                        <FontAwesomeIcon
                          icon={iconMessages}
                          transform="shrink-5"
                        />
                        <span
                          className="fa-layers-counter fa-layers-top-right"
                          style={{
                            backgroundColor:
                              props.messageCount > 0 ? "red" : "white",
                            color: props.messageCount > 0 ? "white" : "black",
                            border:
                              props.messageCount > 0
                                ? "1px solid red"
                                : "1px solid black",
                          }}
                        >
                          {props.messageCount}
                        </span>
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box className="box-default text-center nooverflow rounded">
                    <TextTag
                      text={
                        <span>
                          <span>&nbsp;</span>
                          <span
                            style={{
                              color: props.isEventArchived(props.event)
                                ? "black"
                                : "orange",
                            }}
                          >
                            {configEntzy.URL_POINTERS.MAIN}
                          </span>
                          <span
                            style={{
                              color: props.isEventArchived(props.event)
                                ? "gray"
                                : "white",
                            }}
                          >
                            {props.event.Url}
                          </span>
                          <span>&nbsp;</span>
                        </span>
                      }
                      size="lg"
                      bgColor="black"
                      color="white"
                      shadow={true}
                      rounded={true}
                      textNoCasing={true}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className="box-default text-right">
                    <Typography variant="h6">
                      <span className="fa-layers fa-fw fa-2x">
                        <FontAwesomeIcon
                          icon={iconActvity}
                          transform="shrink-5"
                        />
                        <span
                          className="fa-layers-counter fa-layers-top-right"
                          style={{
                            backgroundColor:
                              props.activityCount > 0 ? "red" : "white",
                            color: props.activityCount > 0 ? "white" : "black",
                            border:
                              props.activityCount > 0
                                ? "1px solid red"
                                : "1px solid black",
                          }}
                        >
                          {props.activityCount}
                        </span>
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                {!props.isEventArchived(props.event) && (
                  <Grid item xs={12}>
                    <Box className="box-default text-center">
                      <TextTag
                        text={props.event.Country}
                        flag={props.event.Country}
                        size={props.size}
                        bgColor="secondary.light"
                        rounded={true}
                        shadow={true}
                      />
                      <TextTag
                        text={props.event.Currency}
                        size={props.size}
                        bgColor="secondary.light"
                        color="black"
                        rounded={true}
                        shadow={true}
                      />
                      <TextTag
                        text={
                          ["ticked"].includes(props.event.Details)
                            ? "Tickets"
                            : props.event.Details
                        }
                        size={props.size}
                        bgColor="secondary.light"
                        color="black"
                        rounded={true}
                        shadow={true}
                      />
                      <TextTag
                        text={props.event.Access}
                        size={props.size}
                        bgColor={
                          props.event.Access === "private"
                            ? "tertiary.main"
                            : "secondary.light"
                        }
                        color="black"
                        rounded={true}
                        shadow={true}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
              {/* end main event opener boundary */}
            </Box>

            {/* ----- footer controls ----- */}
            {props.editing && (
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                  p: configEntzy.APP_SPACING_SM,
                  color: "white",
                  border: configEntzy.BORDER_DASH_DSM,
                  borderRadius: configEntzy.BORDER_SIZE_LG,
                }}
                hidden={!props.editing}
              >
                <Box className="box-default text-center">
                  <TextTag
                    size="md"
                    text={
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <span>
                          {props.isEventArchived(props.event)
                            ? "Unarchive"
                            : "Archive"}
                        </span>
                        <span>&nbsp;&nbsp;</span>
                      </span>
                    }
                    shadow={false}
                    rounded={true}
                    margin={true}
                    bgColor={
                      props.isEventArchived(props.event)
                        ? "primary.light"
                        : "primary.main"
                    }
                    color={
                      props.isEventArchived(props.event) ? "white" : "white"
                    }
                    onClick={() => props.handleArchiveEvent(props.event)}
                  />
                  <TextTag
                    size="md"
                    text={
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <span>Remove</span>
                        <span>&nbsp;&nbsp;</span>
                      </span>
                    }
                    shadow={false}
                    rounded={true}
                    margin={true}
                    bgColor="error.main"
                    onClick={() => props.handleRemoveEvent(props.event)}
                  />
                </Box>
              </Box>
            )}

            {/* end content box */}
          </Box>
          {/* end logo wrapper */}
        </Box>
      </Box>

      {/*
        TODO:
        add event team avatars
        add event team logo
        {event.UserId} {event.Logo}
        <ProfileAvatar size="sm" user={{ identity: props.event.UserId }} /> 
        <TextTag text={props.event.Access}    />
     */}
    </Box>
  );
}

export function CopyButton(props) {
  const [infoText, setInfoText] = useState(props.infoText);
  const [actionIcon, setActionIcon] = useState(0);
  const copyText = "Copied to clipboard!";
  const copyAgainText = "Copied again!";

  const copyTextToClipboard = async function (text) {
    setInfoText(
      infoText === copyText
        ? copyAgainText
        : infoText === copyAgainText
        ? props.infoText
        : copyText
    );
    setActionIcon(
      infoText === copyText ? 1 : infoText === copyAgainText ? 0 : 1
    );
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };
  return (
    <Box className="box-default">
      <Box className="box-default bg-black-t10">
        <TextField
          type="text"
          variant="outlined"
          label={infoText}
          value={props.text}
          disabled={true}
          inputProps={{
            style: {
              fontSize: "120%",
              fontWeight: 900,
              textAlign: props.alignLeft ? "left" : "center",
            },
          }}
          InputProps={{
            startAdornment: props.alignLeft ? (
              <InputAdornment position="start">
                <IconButton
                  aria-label="copy"
                  onClick={() => copyTextToClipboard(props.text)}
                  sx={{ color: props.color ? props.color : "black" }}
                >
                  {actionIcon > 0 ? <ConfirmIcon /> : <CopyIcon />}
                </IconButton>
              </InputAdornment>
            ) : null,
            endAdornment: !props.alignLeft ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="copy"
                  onClick={() => copyTextToClipboard(props.text)}
                  sx={{ color: props.color ? props.color : "black" }}
                >
                  {actionIcon > 0 ? <ConfirmIcon /> : <CopyIcon />}
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          sx={{
            backgroundColor: "transparent",
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: props.color ? props.color : "black",
            },
          }}
          fullWidth
        />
      </Box>
    </Box>
  );
}
